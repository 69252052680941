import React, { useCallback, useEffect } from "react";
import Session from "../pages/SessionView";
import ConnectionDroppedHandler from "../components/ConnectionDroppedHandler";
import Dashboard from "../components/Dashboard";
import { SessionMenuProvider } from "../providers/SessionMenuProvider";
import { DevicesProvider } from "../providers/DevicesProvider";
import { AudioAlertProvider } from "../providers/AudioAlertProvider";
import { VideoProvider } from "../providers/VideoProvider";
import useVideoContext from "../hooks/useVideoContext";
import { useAppStateContext, actions } from "../providers/AppStateProvider";
import { useChatContext } from "../components/Chat";
import TherapistWaitingRoom from "../components/TherapistWaitingRoom/TherapistWaitingRoom";

const { SESSION_END, WAITROOM_SET_SETUP_CHECK } = actions;

const VideoView = () => {
  const { connectFailed } = useVideoContext();
  const { dispatch, state } = useAppStateContext();
  const { session } = state;
  const { setActiveChannel } = useChatContext();

  useEffect(() => {
    if (connectFailed) {
      dispatch({ type: SESSION_END });
    }
  }, [connectFailed, dispatch]);

  const onSessionEnd = useCallback(
    (room, error) => {
      if (!error) {
        setActiveChannel("");
        dispatch({
          type: WAITROOM_SET_SETUP_CHECK,
          setupCheck: false,
        });
      }
    },
    [setActiveChannel, dispatch],
  );

  return !session.active ? (
    <Dashboard connectFailed={connectFailed} />
  ) : (
    <Session
      token={session.token}
      playNotification={false}
      isTherapist={true}
      onSessionEnd={onSessionEnd}
    />
  );
};

const TherapistRoomView = () => {
  // reset VideoProvider after each session
  const { dispatch, state } = useAppStateContext();

  return state.waitingRoom.setupCheck ? (
    <VideoProvider>
      <DevicesProvider>
        <ConnectionDroppedHandler isTherapist={true}>
          <AudioAlertProvider>
            <SessionMenuProvider>
              <VideoView />
            </SessionMenuProvider>
          </AudioAlertProvider>
        </ConnectionDroppedHandler>
      </DevicesProvider>
    </VideoProvider>
  ) : (
    <TherapistWaitingRoom
      onStart={() =>
        dispatch({
          type: WAITROOM_SET_SETUP_CHECK,
          setupCheck: true,
        })
      }
    />
  );
};

export default TherapistRoomView;
