import React, { useState, useContext, createContext, useReducer } from "react";
import * as actions from "./actions";

import reducer from "./reducer";

export const AppStateContext = createContext(null);

const initialState = {
  displayName: "",
  therapist: {},
  websocket: {},
  session: {},
  waitingRoom: {
    participants: [],
    setupCheck: false,
  },
  participantInfo: {},
};

export function AppStateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [clientWaitingRoomChatOpen, setClientWaitingRoomChatOpen] =
    useState(false);

  return (
    <AppStateContext.Provider
      value={{
        setClientWaitingRoomChatOpen,
        clientWaitingRoomChatOpen,
        state,
        dispatch,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppStateContext() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error(
      "useAppStateContext must be used within an AppStateProvider",
    );
  }

  return context;
}

export { actions };
