import React, { useEffect, createContext, useState, useRef } from "react";
import getNav from "@sussex/nav-renderer";
import { getFallbackLanguage } from "../CopyProvider";

export const NavContext = createContext(null);

// Temporary workaround for bug in nav-renderer.
// When fixed, use getNavAsync, which doesn't require including the navDefinition.
const getNavDefintion = async env => {
  const baseUrls = {
    dev: "https://d2yovri7aiwgx3.cloudfront.net",
    qa02: "https://d10f2sfhyq4iy8.cloudfront.net",
    qa03: "https://d2jsxzv58u9j7z.cloudfront.net",
    prod: "https://d3r3iytm0122ax.cloudfront.net",
  };
  const navUrl = `${baseUrls[env]}/member-nav.json`;
  const response = await fetch(navUrl);
  const navDefinition = await response.json();

  return navDefinition;
};

const fetchNav = async (language, country) => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  const navDefinition = await getNavDefintion(env);

  return await getNav({
    env,
    nav: "member-nav",
    brand: "psychologytoday",
    viewingContext: "psychologytoday",
    viewingCountryCode: country?.toLowerCase(),
    viewingLanguageCode: language,
    profileHealthRole: "THERAPIST",
    profileStatus: "ENABLED",
    hasSessions: true,
    hasMatchingEnabled: true, // TODO: get this from profile matching_enabled flag
    navDefinition,
  });
};

export function NavProvider({ children }) {
  const [nav, setNav] = useState([]);
  const fetched = useRef(false);

  useEffect(() => {
    if (fetched.current) {
      return;
    }
    const _getNav = async () => {
      const [language, country] = navigator.language.split("-");

      try {
        fetched.current = true;
        const navResult = await fetchNav(language, country);
        setNav(navResult);
      } catch (e) {
        // Most likely the language doesn't exist.
        // Fetch nav in the default language.
        try {
          const [defaultLanguage, defaultCountry] = getFallbackLanguage(
            navigator.language,
          ).split("-");
          const navResult = await fetchNav(defaultLanguage, defaultCountry);
          setNav(navResult);
        } catch (e) {
          console.error("Failed to fetch nav definition", e);
        }
      }
    };

    _getNav();
  }, [nav]);

  return <NavContext.Provider value={nav}>{children}</NavContext.Provider>;
}
