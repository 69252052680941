import React from "react";
import styled, { css } from "styled-components";
import PTLogo from "../PTLogo";

const FOOTER_HEIGHT_MOBILE = 69;
const FOOTER_HEIGHT_TABLET = 85;

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  width: calc(100% - 32px);
  max-width: 900px;
  margin: 16px 0 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: center;
    align-items: center;
    width: calc(100% - 48px);
    margin: 0;
    flex: 1;
  }
`;

const Child = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: ${({ isLast }) => (isLast ? 1 : 0)};
  ${({ isLast }) =>
    isLast &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        min-height: 400px;
        max-height: 1000px;
        flex: 0;
      }
    `}
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${FOOTER_HEIGHT_MOBILE}px;
  bottom: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: ${FOOTER_HEIGHT_TABLET}px;
  }
`;

const CenterLayout = ({ children }) => {
  return (
    <PageWrapper>
      <Content>
        {React.Children.map(children, (c, i) => (
          <Child isLast={i === React.Children.count(children) - 1}>{c}</Child>
        ))}
      </Content>
      <Footer>
        <a href="/">
          <PTLogo />
        </a>
      </Footer>
    </PageWrapper>
  );
};

export default CenterLayout;
