import React from "react";
import styled from "styled-components";
import { useSessionHistoryContext } from "./SessionHistoryProvider";
import Datepicker from "../DatePicker";
import { TextInput } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";
import searchIcon from "../../assets/search-icon.svg";
import calendarIcon from "../../assets/calendar.svg";
import closeIcon from "../../assets/icon-close.svg";

const RelativeWrapper = styled.div`
  position: relative;
  overflow: visible;
`;

const Clear = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: url(${closeIcon});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  content: " ";
`;

const FilterInput = styled(TextInput)`
  min-width: 125px;
  height: 30px;
  font-size: 12px;
  &,
  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const NameFilter = () => {
  const { nameFilter, setNameFilter, setOffset } = useSessionHistoryContext();
  const [placeholder] = useCopy(["sessionHistory.nameFilter"]);

  const handleNameChange = (value, componentName) => {
    setNameFilter(value || "");
    setOffset("0");
  };

  return (
    <RelativeWrapper>
      <FilterInput
        icon={searchIcon}
        placeholder={placeholder}
        value={nameFilter}
        onChange={e => handleNameChange(e.target.value, "FilterInput")}
      />
      {nameFilter && <Clear onClick={() => handleNameChange(null, "Clear")} />}
    </RelativeWrapper>
  );
};

const StartFilter = () => {
  const { startFilter, setStartFilter, setOffset } = useSessionHistoryContext();
  const [placeholderCopy] = useCopy(["sessionHistory.startFilter"]);

  const handleDateChange = (date, componentName) => {
    setStartFilter(date ? date.getTime() / 1000 : "");
    setOffset("0");
  };

  const startFilterDate = startFilter ? new Date(startFilter * 1000) : null;

  const placeholder = startFilter
    ? startFilterDate.toLocaleDateString([], {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : placeholderCopy;

  return (
    <RelativeWrapper>
      <Datepicker
        onDateChange={date => {
          handleDateChange(date, "Datepicker");
        }}
        icon={calendarIcon}
        placeholder={placeholder}
        value={startFilterDate}
      />
      {startFilter && (
        <Clear
          onClick={() => {
            handleDateChange(null, "Clear");
          }}
        />
      )}
    </RelativeWrapper>
  );
};

export { NameFilter, StartFilter };
