/**
 * Component: Dropdown
 *
 * This is a dropdown menu specifically serving the account menu in the Header
 */
import React, { useRef } from "react";
import styled from "styled-components";
import useClickOutside from "../../../hooks/useClickOutside";

import ViewProfile from "./ViewProfile";

/**
 * Specifically position the dropdown absolutely below the profile icon when on desktop.
 */
const Wrapper = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: block;
  }
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  top: 65px;
  right: 15px;
  width: calc(min(80vw, 317px));
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: 0px 10px 15px rgba(31, 41, 55, 0.1);
  border-radius: 10px;
  z-index: 9;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.light};
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  max-height: calc(100vh - 170px);
  overflow: auto;
`;

const Content = styled.div`
  padding: 20px 25px;
  position: relative;
`;

const Group = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 20px 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
`;

const GroupLabel = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  margin-bottom: 20px;
`;

const NavLink = styled.a`
  display: block;
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: ${({ theme }) => theme.fontSize.large};
  color: ${({ theme }) => theme.colors.default};
  line-height: 1.15;
  text-decoration: none;
  padding: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.light};
    border-radius: 10px;
  }
`;

const Dropdown = ({ items, onClose }) => {
  const ref = useRef(null);
  useClickOutside(ref, onClose);
  return (
    <Wrapper ref={ref}>
      <Header>
        <ViewProfile />
      </Header>
      <ContentWrapper>
        <Content>
          {items.map(group => (
            <Group key={group.id}>
              {group.label && <GroupLabel>{group.label}</GroupLabel>}
              {group.children.map(child => (
                <NavLink key={child.id} href={child.url}>
                  {child.label}
                </NavLink>
              ))}
            </Group>
          ))}
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Dropdown;
