import styled, { css } from "styled-components";

const TableWrapper = styled.div`
  ${({ mobile }) =>
    mobile
      ? css`
          display: block;
          @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
            display: none;
          }
        `
      : css`
          display: none;
          @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
            display: block;
          }
        `}
`;
export default TableWrapper;
