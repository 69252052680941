import useVideoContext from "../../hooks/useVideoContext";
import usePublications from "../../hooks/usePublications";
import useTrack from "../../hooks/useTrack";
import AudioTrack from "../AudioTrack";

const Participant = ({ participant }) => {
  const publications = usePublications(participant);
  const audioPublication = publications.find(
    p => p.kind === "audio" && p.trackName !== "screen-audio",
  );
  const screenAudioPublication = publications.find(
    p => p.trackName === "screen-audio",
  );
  const audioTrack = useTrack(audioPublication);
  const screenAudioTrack = useTrack(screenAudioPublication);

  return (
    <>
      {audioTrack?.kind === "audio" && <AudioTrack track={audioTrack} />}
      {screenAudioTrack?.kind === "audio" && (
        <AudioTrack track={screenAudioTrack} />
      )}
    </>
  );
};

/**
 * ParticipantAudioTracks component will render the audio track for all
 * participants in the room. It is in a separate component so that the audio
 * tracks will always be rendered, and that they will never be unnecessarily
 * unmounted/mounted as the user switches between Gallery View and Speaker View.
 */
const ParticipantAudioTracks = () => {
  const { participants } = useVideoContext();

  return (
    <>
      {participants.map(participant => (
        <Participant key={participant.sid} participant={participant} />
      ))}
    </>
  );
};

export default ParticipantAudioTracks;
