import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import EventBus from "../eventBus";
import { datadogSetUserType } from "../datadog";
import { VideoProvider } from "../providers/VideoProvider";
import { SessionMenuProvider } from "../providers/SessionMenuProvider";
import { WaitingRoomProvider } from "../providers/WaitingRoomProvider";
import { useAppStateContext } from "../providers/AppStateProvider";
import { DevicesProvider } from "../providers/DevicesProvider";
import { AudioAlertProvider } from "../providers/AudioAlertProvider";
import WaitingRoomDetails from "../components/WaitingRoomDetails";
import ConnectionDroppedHandler from "../components/ConnectionDroppedHandler";
import { ChatProvider } from "../components/Chat";
import ClientWelcome from "../components/ClientWelcome";
import ClientCheckIn from "../components/ClientCheckIn";
import Session from "../pages/SessionView";
import SlideoutLayout from "../components/SlideoutLayout";

const PageWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

const VideoView = ({ onBack, onSessionEnd }) => {
  const { state } = useAppStateContext();
  const { session } = state;

  const handleSessionEnd = useCallback(
    (room, error) => {
      if (!error) {
        onSessionEnd();
      }
    },
    [onSessionEnd],
  );

  useEffect(() => {
    datadogSetUserType("client");
  }, []);

  return (
    <ChatProvider eventBus={EventBus}>
      <VideoProvider>
        <DevicesProvider>
          <ConnectionDroppedHandler>
            <AudioAlertProvider>
              <SessionMenuProvider>
                {session.active ? (
                  // SlideoutLayout is temporary to keep session working.
                  // TODO: remove
                  <SlideoutLayout>
                    <Session
                      token={session.token}
                      playNotification={true}
                      isTherapist={false}
                      onSessionEnd={handleSessionEnd}
                    />
                  </SlideoutLayout>
                ) : (
                  <ClientCheckIn onBack={onBack} />
                )}
              </SessionMenuProvider>
            </AudioAlertProvider>
          </ConnectionDroppedHandler>
        </DevicesProvider>
      </VideoProvider>
    </ChatProvider>
  );
};

const ClientRoomView = () => {
  const [welcomeDismissed, setWelcomeDismissed] = useState(false);
  const [sessionEnded, setSessionEnded] = useState(false);

  const handleBack = () => {
    setWelcomeDismissed(false);
  };

  const handleNext = () => {
    setWelcomeDismissed(true);
  };

  const handlesessionEnd = () => {
    setWelcomeDismissed(false);
    setSessionEnded(true);
  };

  return (
    <WaitingRoomProvider>
      <WaitingRoomDetails type="client">
        <PageWrapper>
          {welcomeDismissed ? (
            <VideoView onBack={handleBack} onSessionEnd={handlesessionEnd} />
          ) : (
            <ClientWelcome onNext={handleNext} sessionEnded={sessionEnded} />
          )}
        </PageWrapper>
      </WaitingRoomDetails>
    </WaitingRoomProvider>
  );
};

export default ClientRoomView;
