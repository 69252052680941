import React from "react";
import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import PersonCircle from "../PersonCircle";
import useParticipantInfo from "../../hooks/useParticipantInfo";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 50px;
`;

const ClientName = styled.div`
  flex-shrink: 1;
  font-size: 16px;
  line-height: 1.5;
`;

const ClientList = ({ clients }) => {
  const [waitingClientSingleText, waitingClientMultipleText] = useCopy([
    "dashboard.waitingClientSingle",
    "dashboard.waitingClientMultiple",
  ]);

  const participant1 = useParticipantInfo(clients[0]?.participantId);
  const participant2 = useParticipantInfo(clients[1]?.participantId);
  const participant3 = useParticipantInfo(clients[2]?.participantId);

  const photos = [participant1, participant2, participant3]
    .filter(p => p.displayName !== "")
    .map(c => ({
      imageURL: c.photoUrl,
      name: c.displayName,
    }));

  if (!photos.length) {
    return null;
  }

  return (
    <Wrapper>
      <PersonCircle people={photos} variant="medium" border />
      <ClientName>
        {photos.length === 1
          ? swapCopyVariables(waitingClientSingleText, {
              NAME: photos[0].name,
            })
          : swapCopyVariables(waitingClientMultipleText, {
              NAME: photos[0].name,
              COUNT: photos.length - 1,
            })}
      </ClientName>
    </Wrapper>
  );
};

export default ClientList;
