import React from "react";
import styled from "styled-components";
import { SPAAnchor } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";

const Header = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  background: white;
  padding: 20px 10px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 75px 50px;
  }
`;

const ErrorView = () => {
  const { analyticsEvent } = useAnalyticsContext();
  const [header, message, clickHereText] = useCopy([
    "sessionHistory.noSessionHistoryHeader",
    "sessionHistory.noSessionHistoryMessage",
    "sessionHistory.noSessionHistoryClickHereText",
  ]);

  const onLinkClick = () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "waiting-room",
    });
  };

  return (
    <Wrapper>
      <Header>{header}</Header>
      <span>
        {swapCopyVariables(message, {
          CLICK_HERE: (
            <SPAAnchor onClick={onLinkClick} to="/dashboard">
              {clickHereText}
            </SPAAnchor>
          ),
        })}
      </span>
    </Wrapper>
  );
};

export default ErrorView;
