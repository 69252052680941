import React, { useState, useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";
import IconButton from "./IconButton";
import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle";
import enabledIcon from "../../../assets/active-camera-icon.svg";
import disabledIcon from "../../../assets/inactive-camera-icon.svg";
import useCopy from "../../../hooks/useCopy";
import { useAnalyticsContext } from "../../../providers/AnalyticsProvider";

const EnabledIcon = styled.img`
  width: 43%;
`;

const DisabledIcon = styled.img`
  width: 69%;
  margin-top: 1.7%;
  margin-left: -1%;
`;

export default function VideoToggleButton() {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const { analyticsEvent } = useAnalyticsContext();
  const [disableVideoText, enableVideoText] = useCopy([
    "sessioncontrols.disableVideo",
    "sessioncontrols.enableVideo",
  ]);
  const isMounted = useRef(true);
  const theme = useTheme();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onClick = () => {
    if (isVideoEnabled) {
      setButtonDisabled(true);
      setTimeout(() => isMounted.current && setButtonDisabled(false), 1000);
    }

    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: isVideoEnabled ? "disable-video" : "enable-video",
    });

    toggleVideoEnabled();
  };

  return (
    <IconButton
      onClick={onClick}
      id="toggleVideo"
      background={!isVideoEnabled ? theme.colors.red : undefined}
      tooltip={isVideoEnabled ? disableVideoText : enableVideoText}
      disabled={buttonDisabled}
    >
      {isVideoEnabled ? (
        <EnabledIcon src={enabledIcon} alt={disableVideoText} />
      ) : (
        <DisabledIcon src={disabledIcon} alt={enableVideoText} />
      )}
    </IconButton>
  );
}
