import React from "react";
import styled, { css } from "styled-components";
import TherapistAvailability from "../TherapistAvailability";
import useParticipantInfo from "../../hooks/useParticipantInfo";
import PersonCircle from "../PersonCircle/PersonCircle";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ align }) =>
    align === "center" &&
    css`
      justify-content: center;
    `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const AddLink = styled.a`
  display: block;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
`;

const Name = styled.div`
  color: ${({ theme }) => theme.colors.default};
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: 16px;
  line-height: 1.5;
`;

export default function ChatHeader({
  isTherapist,
  onAdd,
  align = "center",
  participant = {},
}) {
  const { displayName, photoUrl } = useParticipantInfo(
    participant.participantId,
  );
  if (!isTherapist) {
    return <TherapistAvailability isTherapist={false} />;
  }

  if (!participant) {
    return null;
  }

  return (
    <>
      <Wrapper align={align}>
        <PersonCircle imageURL={photoUrl} name={displayName} variant="large" />
        <TextWrapper>
          <Name>{displayName}</Name>
          <AddLink onClick={onAdd}>Add To Session</AddLink>
        </TextWrapper>
      </Wrapper>
    </>
  );
}
