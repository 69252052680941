import React from "react";
import styled, { css } from "styled-components";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import useCopy from "../../hooks/useCopy";
import AvailableToggle from "./AvailableToggle";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-right: 10px;
`;

const Image = styled.img`
  display: block;
  border-radius: 50%;
  object-fit: cover;

  ${({ photoSize }) =>
    css`
      width: ${photoSize};
      height ${photoSize};
    `};
`;

const NameWrapper = styled.div``;

const Name = styled.div`
  color: ${({ theme }) => theme.colors.default};
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  margin-bottom: 3px;
`;

const Status = styled.div`
  color: ${({ theme }) => theme.colors.default};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export default function TherapistAvailability({
  isTherapist,
  photoSize = "40px",
}) {
  const { state } = useAppStateContext();
  const { room: waitingRoom } = useWaitingRoomContext();

  const [statusAvailable, statusUnavailable] = useCopy([
    "chat.statusAvailable",
    "chat.statusUnavailable",
  ]);

  return (
    <Wrapper>
      <ImageWrapper>
        <Image src={waitingRoom.therapistPhotoUrl} photoSize={photoSize} />
      </ImageWrapper>
      <NameWrapper>
        <Name>{waitingRoom.therapistDisplayName}</Name>
        {isTherapist ? (
          <AvailableToggle />
        ) : (
          <Status>
            {state.waitingRoom.therapistAvailable
              ? statusAvailable
              : statusUnavailable}
          </Status>
        )}
      </NameWrapper>
    </Wrapper>
  );
}
