import React from "react";
import styled from "styled-components";
import { Label, TextInput } from "@sussex/react-kit/elements";
import RoomAlias from "./RoomAlias";
import useCopy from "../../hooks/useCopy";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";
import { sanitizeRoomAlias } from "../../utils";

const InputWrapper = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

const ErrorMessage = styled.p`
  font-size: 13px;
  line-height: 2;
  margin: 0;
  color: ${({ theme }) => theme.colors.red};
`;

function NameSettings({ therapistDisplayName, roomAlias, onChange, error }) {
  const [displayNameLabel, roomAliasLabel] = useCopy([
    "settings.displayNameLabel",
    "settings.roomAliasLabel",
  ]);
  const { analyticsEvent } = useAnalyticsContext();

  const displayName = therapistDisplayName.substring(0, 50);

  const handleDisplayNameChange = e =>
    onChange({ therapistDisplayName: e.target.value });

  const handleRoomAliasChange = e =>
    onChange({ roomAlias: sanitizeRoomAlias(e.target.value) });

  const handleDisplayNameFocus = () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "input",
      actionName: "display-name-edit",
    });
  };

  const handleRoomAliasFocus = () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "input",
      actionName: "custom-waiting-room-link-edit",
    });
  };

  return (
    <div>
      <InputWrapper>
        <TextInput
          label={displayNameLabel}
          value={displayName}
          onChange={handleDisplayNameChange}
          onFocus={handleDisplayNameFocus}
          maxLength="50"
        />
      </InputWrapper>
      <InputWrapper>
        <Label>{roomAliasLabel}</Label>
        <RoomAlias
          roomAlias={roomAlias}
          error={!!error}
          onChange={handleRoomAliasChange}
          onFocus={handleRoomAliasFocus}
        />
      </InputWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
}

export default NameSettings;
