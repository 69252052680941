import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import { isNotificationSupported } from "../../hooks/useDesktopNotifications";
import Banner from "../Banner";
import { Button } from "@sussex/react-kit/elements";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";
import { requestDismissDesktopNotifications } from "../../api/httpapi";
import icon from "../../assets/alert-icon.svg";

const DismissWrap = styled.span`
  margin-right: 10px;
`;

const DesktopNotificationsBanner = () => {
  const [permission, setPermission] = useState(
    isNotificationSupported() ? Notification.permission : "denied",
  );

  const {
    room: { desktopNotificationsDismissed, setupReady },
  } = useWaitingRoomContext();
  const { analyticsEvent } = useAnalyticsContext();

  const [message, enableText, disableText] = useCopy([
    "desktopNotifications.prompt",
    "desktopNotifications.enableText",
    "desktopNotifications.disableText",
  ]);

  const show = !(
    desktopNotificationsDismissed ||
    !setupReady ||
    permission === "granted" ||
    permission === "denied" ||
    !isNotificationSupported()
  );

  const handleDismiss = useCallback(() => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "dismiss-new-user-banner",
    });
    requestDismissDesktopNotifications().then(({ success }) => {
      if (success) {
        setPermission("denied");
      }
    });
  }, [analyticsEvent]);

  const handleEnable = useCallback(() => {
    // Safari receives a callback as argument on the requestPermission
    // method, but other browsers return a promise
    const result = Notification.requestPermission(setPermission);
    if (result && result.then) {
      result.then(setPermission).catch(console.error);
    }
  }, [setPermission]);

  useEffect(() => {
    if (show) {
      analyticsEvent({
        eventName: "banner",
        actionType: "show",
        actionName: "desktop-new-user-banner",
      });
    }
  }, [show, analyticsEvent]);

  if (!show) {
    return null;
  }

  const Actions = () => (
    <>
      <DismissWrap>
        <Button
          onClick={handleDismiss}
          variant="white"
          thin
          retainWidthOnMobile
        >
          {disableText}
        </Button>
      </DismissWrap>
      <Button variant="blue" onClick={handleEnable} thin retainWidthOnMobile>
        {enableText}
      </Button>
    </>
  );

  return <Banner icon={icon} message={message} actions={Actions} />;
};

export default DesktopNotificationsBanner;
