import { useEffect, useState } from "react";
import { useAppStateContext } from "../../../providers/AppStateProvider";

export default function useParticipants(room, isConnecting) {
  const { state } = useAppStateContext();
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    if (isConnecting) return;
    setParticipants(
      Array.from(room.participants ? room.participants.values() : []),
    );
  }, [room, isConnecting]);

  useEffect(() => {
    const participantConnected = participant => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };
    const participantDisconnected = participant => {
      const participantType =
        state.participantInfo[participant.identity].participantType;
      if (participantType === "therapist") {
        setTimeout(() => {
          room.disconnect();
        }, 100);
      }
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant),
      );
    };
    // All we are going to do is update the participants list to trigger rerenders
    const handleReconnections = participant => {
      setParticipants(prevParticipants => {
        const existingParticipants = prevParticipants.filter(
          p => p.identity !== participant.identity,
        );
        return [...existingParticipants, participant];
      });
    };
    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.on("participantReconnecting", handleReconnections);
    room.on("participantReconnected", handleReconnections);
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
      room.off("participantReconnecting", handleReconnections);
      room.off("participantReconnected", handleReconnections);
    };
  }, [room, state.participantInfo]);

  return participants;
}
