import React, { useState } from "react";
import styled from "styled-components";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import { useAppStateContext, actions } from "../../providers/AppStateProvider";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";
import { H1, Button } from "@sussex/react-kit/elements";
import NameSettings from "./NameSettings";
import ClientTimerSettings from "./ClientTimerSettings";
import BAASettings from "./BAASettings";
import ChatSettings from "./ChatSettings";
import RoomSettingsNotification from "./RoomSettingsNotification";
import ConfirmationModal from "../ConfirmationModal";
import Panel from "../Panel";
import { requestUpdateRoom } from "../../api/httpapi";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import { isdef } from "@sussex/react-kit/utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ScrollPanel = styled(Panel)`
  overflow: auto;
  height: 100%;
  max-height: calc(100vh - 210px);
`;

const PanelBody = styled.div`
  overflow: auto;
`;

const Section = styled.div`
  padding: 24px 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  width: 100%;
  background: white;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 24px;
  /* Little bit of negative margin to cover up the
  settings panel's bottom border radius */
  margin-top: -16px;
  border-radius: 0 0 16px 16px;
  z-index: 1;
`;

function RoomSettings() {
  const { room, setRoom } = useWaitingRoomContext();
  const { state: appState, dispatch } = useAppStateContext();
  const { analyticsEvent } = useAnalyticsContext();
  const [therapistDisplayName, setTherapistDisplayName] = useState(
    room.therapistDisplayName,
  );
  const [roomAlias, setRoomAlias] = useState(room.roomAlias);
  const [oldRoomAlias, setOldRoomAlias] = useState(null);
  const [clientTimer, setClientTimer] = useState(room.clientTimer);
  const [available, setAvailable] = useState(
    appState.waitingRoom.therapistAvailable,
  );
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [
    header,
    save,
    confirmationTitle,
    confirmationBody,
    errorInUse,
    errorInvalid,
    errorInternal,
  ] = useCopy([
    "settings.header",
    "settings.save",
    "settings.confirmationTitle",
    "settings.confirmationBody",
    "setup.errorUrlInUse",
    "setup.errorInvalid",
    "setup.errorInternal",
  ]);

  const isRoomUpdate =
    therapistDisplayName !== room.therapistDisplayName ||
    roomAlias !== room.roomAlias ||
    clientTimer !== room.clientTimer;

  const isAvailabilityUpdate =
    available !== appState.waitingRoom.therapistAvailable;

  const saveDisabled = () =>
    busy ||
    !therapistDisplayName ||
    !roomAlias ||
    !(isRoomUpdate || isAvailabilityUpdate);

  const onChange = data => {
    if (error) {
      setError(null);
    }
    if (message) {
      setMessage(null);
    }
    if (isdef(data.therapistDisplayName)) {
      setTherapistDisplayName(data.therapistDisplayName);
    }
    if (isdef(data.roomAlias)) {
      setRoomAlias(data.roomAlias);
    }
    if (isdef(data.clientTimer)) {
      setClientTimer(data.clientTimer);
    }
    if (isdef(data.available)) {
      setAvailable(data.available);
    }
  };

  const handleSave = event => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "settings-save",
    });

    // If room Alias change, show dialog
    if (roomAlias !== room.roomAlias) {
      setConfirmationOpen(true);
      return;
    }
    // if not, submit
    handleSubmit(event);
  };

  const handleSubmit = async evt => {
    evt.preventDefault();
    setConfirmationOpen(false);
    setBusy(true);

    try {
      await requestUpdateRoom(
        roomAlias,
        therapistDisplayName,
        clientTimer,
        available,
      );

      setOldRoomAlias(room.roomAlias);

      if (isRoomUpdate) {
        setRoom(state => {
          return {
            ...state,
            roomAlias,
            therapistDisplayName,
            clientTimer,
          };
        });
      }
      if (isAvailabilityUpdate) {
        dispatch({
          type: actions.WAITROOM_SET_THERAPIST_AVAILABLE,
          available: available,
          isInitialState: false,
        });
      }
      setMessage("success");
    } catch (err) {
      switch (err.status) {
        case 400:
          setError(errorInvalid);
          break;
        case 409:
          setError(errorInUse);
          break;
        default:
          setError(errorInternal);
      }

      setMessage("fail");
    }

    setBusy(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Wrapper>
        <Body>
          <Content>
            <RoomSettingsNotification
              message={message}
              oldRoomAlias={oldRoomAlias}
              newRoomAlias={roomAlias}
            />
            <ScrollPanel>
              <PanelBody>
                <H1>{header}</H1>
                <Section>
                  <NameSettings
                    therapistDisplayName={therapistDisplayName}
                    roomAlias={roomAlias}
                    onChange={onChange}
                    error={error}
                  />
                </Section>
                <Section>
                  <ClientTimerSettings
                    onChange={onChange}
                    clientTimer={clientTimer}
                  />
                </Section>
                <Section>
                  <ChatSettings available={available} onChange={onChange} />
                </Section>
                {room.countryCode === "US" ? (
                  <Section>
                    <BAASettings />
                  </Section>
                ) : null}
              </PanelBody>
            </ScrollPanel>
          </Content>
          <Footer>
            <Button onClick={handleSave} disabled={saveDisabled()}>
              {save}
            </Button>
          </Footer>
        </Body>
      </Wrapper>
      {confirmationOpen && (
        <ConfirmationModal
          title={confirmationTitle}
          onConfirm={handleSubmit}
          onClose={() => {
            setConfirmationOpen(false);
          }}
          maxWidth="600px"
        >
          {swapCopyVariables(confirmationBody, {
            URL: `https://${window.location.hostname}/${roomAlias}`,
          })}
        </ConfirmationModal>
      )}
    </>
  );
}

export default RoomSettings;
