import React from "react";
import { useSetPage } from "../providers/AnalyticsProvider";
import SessionHistory from "../components/SessionHistory";

const View = () => {
  useSetPage("SessionsHistory");

  return <SessionHistory />;
};

export default View;
