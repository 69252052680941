import React from "react";
import styled from "styled-components";
import icon from "../../assets/fail.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: white;
  padding: 15px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const ErrorView = ({ message }) => {
  return (
    <Wrapper>
      <Icon src={icon} />
      {message}
    </Wrapper>
  );
};

export default ErrorView;
