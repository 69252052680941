import React from "react";
import styled, { css } from "styled-components";
import { TextInput } from "@sussex/react-kit/elements";
import useWindowSize from "../../hooks/useWindowSize";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-wrap: nowrap;
  }
`;

const Input = styled(TextInput)`
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

const baseContainerStyle = css`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: auto;
  }
`;

const aliasContainerStyle = css`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: auto;
    flex: 1;
  }
`;

function RoomAlias({ roomAlias, error, onChange }) {
  const { width } = useWindowSize();
  const base = `${window.location.hostname}/`;

  return (
    <Wrapper>
      <Input
        disabled
        length={base.length}
        size={base.length}
        value={`${window.location.hostname}/`}
        stacked={width < 768}
        adjacent={width >= 768}
        containerStyle={baseContainerStyle}
      />
      <Input
        value={roomAlias}
        onChange={onChange}
        error={error}
        stacked={width < 768}
        adjacent={width >= 768}
        containerStyle={aliasContainerStyle}
      />
    </Wrapper>
  );
}

export default RoomAlias;
