import React, { useState, useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";
import { useMediaQuery } from "@mui/material";
import { requestClientAuthorization } from "../../api/httpapi";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import useVideoContext from "../../hooks/useVideoContext";
import { useAppStateContext, actions } from "../../providers/AppStateProvider";
import { datadogEvent } from "../../datadog";
import CenterLayout from "../CenterLayout";
import LocalVideoPreview from "../LocalVideoPreview";
import Header from "./Header";
import SlideoutLayout from "../SlideoutLayout";
import VideoAndMicSettings from "../VideoAndMicSettings";
import { WaitingRoomChat } from "../Chat";
import { useChatContext } from "../Chat/ChatProvider";
import Websocket from "../Websocket";
import PhotoCanvas from "../PhotoCanvas";
import TermsOfService from "../TermsOfService";
import { ConnectionErrorView, VideoErrorView } from "./Errors";
import CheckInForm from "./CheckInForm";
import CheckedIn from "./CheckedIn";

const { WEBSOCKET_SET_CONNECT, WAITROOM_SET_DISPLAYNAME } = actions;

const Wrapper = styled.div`
  background: white;
  box-shadow: ${({ theme }) => theme.panel.boxShadow};
  border: ${({ theme }) => theme.panel.border};
  border-radius: 16px;
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: auto;
    flex-direction: row-reverse;
  }
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50%;
  }
`;

const VideoSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50%;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  & > * {
    flex: 1;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex: 1;
    margin-bottom: 16px;
  }
`;

const ClientCheckIn = ({ onBack }) => {
  const [changingMediaSettings, setChangingMediaSettings] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [tosAccepted, setTosAccepted] = useState(false);
  const { activeChannel, setActiveChannel, getUnreadCount } = useChatContext();

  const { room } = useWaitingRoomContext();
  const { dispatch } = useAppStateContext();
  const theme = useTheme();
  const { isAcquiringLocalTracks, localTrackError } = useVideoContext();
  const [loading, setLoading] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [video, setVideo] = useState(null);
  const {
    state: { therapist, websocket },
  } = useAppStateContext();
  const unreadChatMessages = getUnreadCount(therapist.participantId);
  const xs = !useMediaQuery(`(min-width:${theme.breakpoints.tablet})`);
  const chatHasOpened = useRef(false);
  const canvasRef = useRef(null);

  const disabled =
    !displayName.match(/[\p{L}]{2,32}/u) ||
    isAcquiringLocalTracks ||
    localTrackError ||
    loading ||
    !tosAccepted;

  // Render a video frame and capture the client's photo so the therapist can
  // visually identify the client.
  const capturePhoto = (video, canvas) => {
    // If videoWidth or videoHeight not set, trigger video error.
    if (!video || !video.videoWidth || !video.videoHeight) {
      throw new Error("video");
    }

    const ctx = canvas.getContext("2d");

    var resizeCanvas = document.createElement("canvas");
    var resizeContext = resizeCanvas.getContext("2d");

    // Compress photo to 1px and measure its color. If the combined rgb value or
    // the alpha value is 0, the video stream has a problem or the camera is
    // covered.
    canvas.width = canvas.height = 1;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const compressed = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const color = compressed.data;
    if (color[0] + color[1] + color[2] === 0 || color[3] === 0) {
      throw new Error("video");
    }

    const maxWidth = 200;
    const maxHeight = 200;
    let ratio = 1;
    if (video.videoWidth > maxWidth) {
      ratio = maxWidth / video.videoWidth;
    } else if (video.videoHeight > maxHeight) {
      ratio = maxHeight / video.videoHeight;
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    resizeCanvas.width = video.videoWidth * ratio;
    resizeCanvas.height = video.videoHeight * ratio;
    resizeContext.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      resizeCanvas.width,
      resizeCanvas.height,
    );

    return resizeCanvas.toDataURL("image/png");
  };

  const checkin = async (roomAlias, name, photo, consent) => {
    const succeeded = await requestClientAuthorization(
      roomAlias,
      name,
      photo,
      consent,
    );
    if (!succeeded) {
      throw new Error("connection");
    }
    dispatch({
      type: WEBSOCKET_SET_CONNECT,
      connect: true,
    });
  };

  const handleTosClick = () => {
    if (loading) {
      return;
    }
    setTosAccepted(!tosAccepted);
  };

  // handle the button submission and orchestrate the checkin steps.
  const handleSubmit = async evt => {
    evt.preventDefault();
    setLoading(true);

    datadogEvent({
      category: "waiting_room",
      feature: "checkin",
      event: "clicked",
      component: "ClientCheckin.CheckInView",
    });

    try {
      const photo = capturePhoto(video, canvasRef.current);
      await checkin(room.roomAlias, displayName, photo, tosAccepted);

      datadogEvent({
        category: "waiting_room",
        event: "checked_in",
        component: "ClientCheckin.CheckInView",
      });

      dispatch({
        displayName,
        type: WAITROOM_SET_DISPLAYNAME,
      });
    } catch (err) {
      console.error("Error Checking In", err);
      if (err.message === "video") {
        setVideoError(true);
      } else {
        setConnectionError(true);
      }
    }
  };

  const reset = () => {
    setVideoError(false);
  };

  // Open the chat panel on first chat message if the client has not already
  // opened the chat panel and the settings panel is not open.
  // Non-mobile view only.
  useEffect(() => {
    if (activeChannel) {
      chatHasOpened.current = true;
    }
  }, [activeChannel]);

  useEffect(() => {
    if (xs) return;
    if (chatHasOpened.current) return;
    if (!unreadChatMessages) return;
    if (changingMediaSettings) {
      chatHasOpened.current = true;
      return;
    }
    setActiveChannel(therapist.participantId);
  }, [
    unreadChatMessages,
    therapist.participantId,
    setActiveChannel,
    xs,
    changingMediaSettings,
  ]);

  return (
    <SlideoutLayout>
      <CenterLayout>
        <Wrapper>
          <VideoSection>
            {xs && (
              <Header
                name={room.profileName}
                photoUrl={room.therapistPhotoUrl}
                onBack={onBack}
              />
            )}
            <VideoWrapper>
              <LocalVideoPreview
                setVideo={setVideo}
                showMediaControls={websocket.isConnected}
                onMediaSettingsOpen={() => setChangingMediaSettings(true)}
              />
              <PhotoCanvas canvasRef={canvasRef} />
            </VideoWrapper>
          </VideoSection>
          <FormSection>
            {!xs && (
              <Header
                name={room.profileName}
                photoUrl={room.therapistPhotoUrl}
                onBack={onBack}
              />
            )}
            <FormWrapper>
              {videoError ? (
                <VideoErrorView
                  reset={reset}
                  openSettings={() => setChangingMediaSettings(true)}
                />
              ) : connectionError || websocket.error ? (
                <ConnectionErrorView reset={() => window.location.reload()} />
              ) : websocket.isConnected ? (
                <CheckedIn />
              ) : (
                <CheckInForm
                  onSubmit={handleSubmit}
                  displayName={displayName}
                  onDisplayNameChange={setDisplayName}
                  disabled={disabled}
                  tosAccepted={tosAccepted}
                  toggleAccepted={handleTosClick}
                  loading={loading}
                />
              )}
            </FormWrapper>
          </FormSection>
        </Wrapper>
      </CenterLayout>
      <Websocket />
      <TermsOfService />
      <VideoAndMicSettings
        active={changingMediaSettings}
        onClose={() => setChangingMediaSettings(false)}
        overlap={true}
        testsEnabled={true}
      />
      <WaitingRoomChat
        isTherapist={false}
        participant={therapist}
        channel={therapist.participantId}
        position="right"
        overlap={true}
        insets={{
          mobile: 81,
          tablet: 113,
          tabletPortrait: 113,
        }}
      />
    </SlideoutLayout>
  );
};

export default ClientCheckIn;
