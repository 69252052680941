import React from "react";
import styled from "styled-components";
import IconButton from "./IconButton";
import useCopy from "../../../hooks/useCopy";
import OverflowMenu from "../../OverflowMenu";
import gearIcon from "../../../assets/gear-icon.svg";
import { useAnalyticsContext } from "../../../providers/AnalyticsProvider";

const OverflowWrap = styled.div`
  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    width: 5px;
  }
`;

const GearIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export default function SettingsButton({
  settingsOpen,
  setSettingsOpen,
  onClick,
  variant = "overflow",
}) {
  const [tooltip] = useCopy(["sessioncontrols.options"]);
  const { analyticsEvent } = useAnalyticsContext();

  const handleClick = () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "settings",
    });
    onClick ? onClick() : setSettingsOpen(!settingsOpen);
  };

  return (
    <IconButton onClick={handleClick} tooltip={tooltip}>
      <OverflowWrap>
        {variant === "overflow" ? (
          <OverflowMenu />
        ) : (
          <GearIcon src={gearIcon} />
        )}
      </OverflowWrap>
    </IconButton>
  );
}
