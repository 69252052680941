/**
 * Component: View Profile
 *
 * This exists to provide a unified UI for the combined profile image / profile
 * name / back to account link that exists in the account menu dropdown and
 * sidebar menu.
 */
import React from "react";
import styled from "styled-components";
import { Link } from "@sussex/react-kit/elements";
import { useWaitingRoomContext } from "../../../providers/WaitingRoomProvider";
import useCopy from "../../../hooks/useCopy";
import ProfileImage from "./ProfileImage";

const directoryUrl =  process.env.REACT_APP_DIRECTORY_URL;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 10px;
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const AccountLink = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 15px;
`;

const ViewProfile = () => {
  const { room } = useWaitingRoomContext();
  const backToAccount = () => {
    window.location.href = `${directoryUrl}/profile/${room.profileId}?preview=1`;
  };

  const [viewProfile] = useCopy(["navigation.viewProfile"]);

  return (
    <Wrapper>
      <ProfileImage src={room.therapistPhotoUrl} alt={room.profileName} />
      <Information>
        <Name>{room.profileName}</Name>
        <AccountLink onClick={backToAccount}>{viewProfile}</AccountLink>
      </Information>
    </Wrapper>
  );
};

export default ViewProfile;
