import React from "react";
import styled from "styled-components";
import ParticipantTracks from "../ParticipantTracks";
import InitialsBadge from "./InitialsBadge";
import Reconnecting from "./Reconnecting";
import ParticipantControls from "../ParticipantControls";
import usePublications from "../../hooks/usePublications";
import useIsTrackEnabled from "../../hooks/useIsTrackEnabled";
import useVideoContext from "../../hooks/useVideoContext";
import useTrack from "../../hooks/useTrack";
import useHoverOrTouch from "../../hooks/useHoverOrTouch";
import useParticipantInfo from "../../hooks/useParticipantInfo";

const ParticipantWrap = styled.div`
  height: 100%;
  overflow: hidden;
  background-color: black;
`;

export default function Participant({
  participant,
  disableVideo,
  enableScreenShare,
  isScreenshare,
  showAdminControls,
  setVideo,
  full,
  children,
  isMain = false,
}) {
  const [hoverRef, isHovering] = useHoverOrTouch();
  const {
    room: { localParticipant },
    selectedParticipant,
    setSelectedParticipant,
    screenShareParticipant,
    sendMessage,
  } = useVideoContext();
  const { displayName } = useParticipantInfo(participant.identity);
  const publications = usePublications(participant);
  const videoPublication = publications.find(p => p.kind === "video");
  const isVideoEnabled = Boolean(videoPublication);
  const audioPublication = publications.find(p => p.kind === "audio");
  const audioTrack = useTrack(audioPublication);
  const isAudioEnabled = useIsTrackEnabled(audioTrack);

  const pinParticipant = () => {
    setSelectedParticipant(participant);
  };

  const toggleMuteParticipant = () => {
    const message = isAudioEnabled ? "mute" : "unmute";

    sendMessage({
      type: message,
      to: participant.identity,
    });
  };

  const removeParticipant = () => {
    sendMessage({
      type: "kick",
      to: participant.identity,
    });
  };

  const isReconnecting = participant.state === "reconnecting";

  // If video is disabled, we need to keep audio tracks around, but leave no visible trace
  if (disableVideo) {
    return (
      <ParticipantTracks
        isMain={isMain}
        participant={participant}
        enableScreenShare={enableScreenShare}
        videoPriority="low"
        setVideo={setVideo}
        disableVideo={true}
      />
    );
  }

  return (
    <ParticipantWrap id={`participant-${participant.identity}`} ref={hoverRef}>
      {isReconnecting ? (
        <Reconnecting />
      ) : (
        <>
          <ParticipantTracks
            isMain={isMain}
            participant={participant}
            enableScreenShare={enableScreenShare}
            videoPriority="low"
            setVideo={setVideo}
          />
          {!isVideoEnabled && !isScreenshare && (
            <InitialsBadge thumbnail={isMain} name={displayName} />
          )}
        </>
      )}
      {children}
      {!isMain && (
        <ParticipantControls
          isMain={isMain}
          isVisible={isHovering}
          showAdminControls={showAdminControls}
          isSelected={selectedParticipant === participant}
          setSelected={pinParticipant}
          isAudioEnabled={isAudioEnabled}
          toggleMuteParticipant={toggleMuteParticipant}
          showPinControls={
            !screenShareParticipant ||
            screenShareParticipant === localParticipant
          }
          removeParticipant={removeParticipant}
          identity={participant.identity}
          displayName={displayName}
        />
      )}
    </ParticipantWrap>
  );
}
