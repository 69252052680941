import { theme } from "@sussex/react-kit";
import { createTheme } from "@mui/material/styles";

export default createTheme({
  ...theme,
  breakpoints: {
    tablet: "768px",
    tabletInt: 768,
    desktop: "1000px",
    desktopInt: 1000,
    xl: "1200px",
    xlInt: 1200,
  },
  header: {
    background: theme.colors.primaryBlue,
    height: "70px",
  },
  sidebar: {
    background: "none",
  },
  nav: {
    fontColor: theme.colors.default,
    itemHeight: "100%",
  },
  panel: {
    boxShadow: "none",
    border: `1px solid ${theme.colors.border}`,
  },
  footer: {
    background: "none",
  },
  ptlogo: {
    color: "#477BE4",
  },
});
