import { useState, useRef, useEffect } from "react";

export default function useHoverOrTouch() {
  const [active, setActive] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    const handleMouseOver = () => setActive(true);
    const handleMouseOut = () => setActive(false);
    const handleTouchOn = e => {
      e.stopPropagation();
      setActive(true);
    };
    const handleTouchOff = e => {
      if (e.target !== element) {
        setActive(false);
      }
    };

    if (element) {
      element.addEventListener("mouseenter", handleMouseOver);
      element.addEventListener("mouseleave", handleMouseOut);
      element.addEventListener("touchstart", handleTouchOn);
      window.addEventListener("touchstart", handleTouchOff);

      return () => {
        element.removeEventListener("mouseenter", handleMouseOver);
        element.removeEventListener("mouseleave", handleMouseOut);
        element.removeEventListener("touchstart", handleTouchOn);
        window.removeEventListener("touchstart", handleTouchOff);
      };
    }
  }, []);

  return [ref, active];
}
