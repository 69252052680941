import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import PersonCircle from "../PersonCircle";
import waitingAvatar from "../../assets/waiting-avatar.svg";

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Text = styled.div`
  flex: 1;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
`;

const GhostRow = () => {
  const [waitingText] = useCopy(["dashboard.waitingForClient"]);

  return (
    <Wrapper>
      <PersonCircle
        imageURL={waitingAvatar}
        name={waitingText}
        variant="large"
      />
      <Text>{waitingText}</Text>
    </Wrapper>
  );
};

export default GhostRow;
