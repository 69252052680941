/**
 * get the copy required for the invite to session modal
 */
import useCopy from "../../hooks/useCopy";

const Copy = () =>
  useCopy([
    "dashboard.invitemodal.title",
    "dashboard.invitemodal.nameLabel",
    "dashboard.invitemodal.emailLabel",
    "dashboard.invitemodal.startTimeLabel",
    "dashboard.invitemodal.noStartTime",
    "dashboard.invitemodal.messageTitle",
    "dashboard.invitemodal.messagePlaceholder",
    "dashboard.invitemodal.buttonText",
    "dashboard.invitemodal.sendingText",
    "dashboard.invitemodal.defaultMessage",
    "dashboard.invitemodal.noStartTimeMessage",
    "dashboard.invitemodal.senderText",
    "global.copyInvitationURL",
    "global.copiedToClipboard",
    "dashboard.invitemodal.missingName",
    "dashboard.invitemodal.invalidEmail",
    "dashboard.invitemodal.customMessageTitle",
    "dashboard.invitemodal.defaultSubject",
    "dashboard.invitemodal.noStartTimeSubject",
    "dashboard.inviteModal.mobileApp",
    "appLinks.ios.alt",
    "appLinks.android.alt",
  ]);

export default Copy;
