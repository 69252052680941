import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import ClientRoomView from "./pages/ClientRoomView";
import ClientExitRoomView from "./pages/ClientExitRoomView";
import Admin from "./pages/Admin";
import LandingView from "./pages/LandingView";
import NotFoundView from "./pages/NotFoundView";

function AppRouter() {
  return (
    <Router>
      <App>
        <Routes>
          <Route path="/dashboard/*" element={<Admin />} />
          <Route path="/" element={<LandingView />} />
          <Route path="/:roomAlias" element={<ClientRoomView />} />
          <Route path="/:roomAlias/complete" element={<ClientExitRoomView />} />
          <Route path="*" element={<NotFoundView />} />
        </Routes>
      </App>
    </Router>
  );
}

export default AppRouter;
