import { useState } from "react";

export default function useSelectedParticipant(room) {
  const [selectedParticipant, _setSelectedParticipant] = useState(null);

  const setSelectedParticipant = participant =>
    _setSelectedParticipant(prevParticipant =>
      prevParticipant === participant ? null : participant,
    );

  return { selectedParticipant, setSelectedParticipant };
}
