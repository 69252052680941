/**
 * Component: AccountMenu
 *
 * The account menu component contains the initial view of
 * `Profile Name | ProfileImage`, that when clicked will open either a Dropdown
 * or a MobileNavigation menu with supporting account navigation items within.
 */
import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import ProfileImage from "./ProfileImage";
import { useWaitingRoomContext } from "../../../providers/WaitingRoomProvider";
import useNav from "../../../hooks/useNav";

import Dropdown from "./Dropdown";
import AccountPanel from "./AccountPanel";

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  appearance: none;
  border: none;
  background: none;
`;
Wrapper.defaultProps = {
  type: "button",
};

const Name = styled.div`
  display: none;
  color: white;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: block;
    margin-right: 10px;
  }
`;

const AccountMenu = () => {
  const { room } = useWaitingRoomContext();
  const [active, setActive] = useState(false);
  const accountNav = useNav("account");

  const {
    breakpoints: { desktop: desktopBreakpoint },
  } = useTheme();

  const query = window.matchMedia(`(min-width: ${desktopBreakpoint})`);

  const Menu = query.matches ? Dropdown : AccountPanel;

  const handleOpen = () => {
    setActive(!active);
  };

  return (
    <>
      <Wrapper onClick={handleOpen}>
        <Name>{room.profileName}</Name>
        <ProfileImage src={room.therapistPhotoUrl} alt={room.profileName} />
      </Wrapper>
      {active && (
        <Menu
          id="account"
          onClose={() => setActive(false)}
          label="Account"
          items={accountNav.children}
        />
      )}
    </>
  );
};

export default AccountMenu;
