import useVideoContext from "./useVideoContext";
import { useEffect } from "react";
import { isSessionEndedCode } from "../twilioErrors";
import { useAppStateContext, actions } from "../providers/AppStateProvider";

const { SESSION_END } = actions;

export default function useSessionEnded(onSessionEnd) {
  const { room } = useVideoContext();
  const { dispatch } = useAppStateContext();

  useEffect(() => {
    const onDisconnect = (room, error) => {
      dispatch({ type: SESSION_END });

      const err = error && !isSessionEndedCode(error.code) ? error : null;
      onSessionEnd(room, err);
    };
    room.on("disconnected", onDisconnect);
    return () => room.off("disconnected", onDisconnect);
  }, [room, onSessionEnd, dispatch]);
}
