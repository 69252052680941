import React from "react";
import useTrack from "../../hooks/useTrack";
import VideoTrack from "../VideoTrack";

export default function Publication({
  publication,
  isLocal,
  isMain,
  disableVideo,
  videoPriority,
  setVideo,
}) {
  const track = useTrack(publication);

  if (!track || track.isStopped) {
    return null;
  }

  // Even though we only have one case here, let's keep this switch() in case
  // we even need to add a 'data' case for rendering DataTracks.
  switch (track.kind) {
    case "video":
      return disableVideo ? null : (
        <VideoTrack
          track={track}
          priority={videoPriority}
          isLocal={track.name.includes("camera") && isLocal}
          isScreenShare={track.name.includes("screen")}
          setVideo={setVideo}
          isMain={isMain}
        />
      );
    default:
      return null;
  }
}
