import React from "react";
import styled from "styled-components";
import { Lightswitch, Label } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  margin-left: 8px;
  font-size: 16px;
  line-height: 1.5;
`;

function ClientTimerSettings({ onChange, clientTimer }) {
  const [title, label] = useCopy(["settings.timer", "settings.clientTimer"]);
  const { analyticsEvent } = useAnalyticsContext();

  const handleClientTimerChange = e => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: clientTimer
        ? "disable-session-timer"
        : "enable-session-timer",
    });
    onChange({ clientTimer: !clientTimer });
  };

  return (
    <>
      <Label>{title}</Label>
      <ToggleWrapper>
        <Lightswitch
          checked={clientTimer}
          onChange={handleClientTimerChange}
          id="therapistTimeToggle"
          size="large"
        />
        <ToggleLabel>{label}</ToggleLabel>
      </ToggleWrapper>
    </>
  );
}

export default ClientTimerSettings;
