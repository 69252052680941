import React from "react";
import RoomSettings from "../components/RoomSettings";
import { useSetPage } from "../providers/AnalyticsProvider";

const View = () => {
  useSetPage("SessionsSettings");

  return <RoomSettings />;
};

export default View;
