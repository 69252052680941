import React, { useEffect } from "react";
import styled from "styled-components";
import ErrorAlert from "../ErrorAlert";
import useCopy from "../../hooks/useCopy";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";

const Wrapper = styled.div`
  margin: 20px auto;
`;

export default function ConnectError({ reason }) {
  const [errorMessage] = useCopy(["dashboard.connectionError"]);
  const { analyticsEvent } = useAnalyticsContext();

  useEffect(() => {
    analyticsEvent({
      eventName: "banner",
      actionType: "show",
      actionName: "connection-error",
      context: {
        reason,
      },
    });
  }, [analyticsEvent, reason]);

  return (
    <Wrapper>
      <ErrorAlert message={errorMessage} />
    </Wrapper>
  );
}
