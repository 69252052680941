import { useState, useCallback } from "react";
import { datadogEvent } from "../../../datadog";

export default function useScreenShareToggle(room, onError) {
  const [screenSharePublication, setScreenSharePublication] = useState(null);

  const stopScreenShare = useCallback(() => {
    const screenSharePublicationList = screenSharePublication && [
      screenSharePublication,
    ];
    const publications =
      screenSharePublicationList ||
      Array.from(room.localParticipant.tracks.values()).filter(track =>
        track.trackName.includes("screen"),
      );
    if (publications.length > 0) {
      publications.forEach(publication => {
        // TODO: remove this if the SDK is updated to emit this event
        room.localParticipant.emit("trackUnpublished", publication);
        room.localParticipant.unpublishTrack(publication.track);
        publication.track && publication.track.stop();
      });
      setScreenSharePublication(null);

      datadogEvent({
        category: "session",
        feature: "screenshare",
        event: "ended",
        component: "VideoProvider.useScreenShareToggle",
      });
    }
  }, [room, screenSharePublication]);

  const startScreenShare = useCallback(() => {
    navigator.mediaDevices
      .getDisplayMedia({
        audio: true,
        // use twilio recommended hard coded values
        video: {
          frameRate: 10,
        },
      })
      .then(stream => {
        const videoTrack = stream
          .getTracks()
          .find(({ kind }) => kind === "video");
        const audioTrack = stream
          .getTracks()
          .find(({ kind }) => kind === "audio");
        // All video tracks are published with 'low' priority. This works because the video
        // track that is displayed in the 'MainParticipant' component will have it's priority
        // set to 'high' via track.setPriority()
        room.localParticipant
          .publishTrack(videoTrack, {
            name: "screen", // Tracks can be named to easily find them later
            priority: "high",
          })
          .then(trackPublication => {
            setScreenSharePublication(trackPublication);
            videoTrack.onended = stopScreenShare;

            datadogEvent({
              category: "session",
              feature: "screenshare",
              event: "started",
              component: "VideoProvider.useScreenShareToggle",
            });
          })
          .catch(onError);
        room.localParticipant
          .publishTrack(audioTrack, {
            name: "screen-audio", // Tracks can be named to easily find them later
            priority: "high",
          })
          .catch(onError);
      })
      .catch(error => {
        // Don't display an error if the user closes the screen share dialog
        if (error.name !== "AbortError" && error.name !== "NotAllowedError") {
          onError(error);
        }
      });
  }, [room, onError, stopScreenShare]);

  const toggleScreenShare = useCallback(() => {
    screenSharePublication && screenSharePublication.isTrackEnabled
      ? stopScreenShare()
      : startScreenShare();
  }, [stopScreenShare, startScreenShare, screenSharePublication]);

  return {
    toggleScreenShare,
    isScreenSharing:
      screenSharePublication && screenSharePublication.isTrackEnabled,
  };
}
