import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Panel from "../Panel";
import useCopy from "../../hooks/useCopy";
import { Anchor, H2 as BaseH2, H4 } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { requestUpdateRoom } from "../../api/httpapi";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";
import ErrorAlert from "../ErrorAlert";
import mobileIllustration from "../../assets/mobile-illustration.png";
import closeIcon from "../../assets/icon-close.svg";
import freeIcon from "../../assets/icon-free.svg";
import secureIcon from "../../assets/icon-secure.svg";
import mobileIcon from "../../assets/icon-mobile.svg";
import {
  PROFESSIONALS_APP_URL_IOS,
  PROFESSIONALS_APP_URL_ANDROID,
} from "../../mobileApps";

const Wrapper = styled.div`
  overflow: hidden;
`;

const HeaderWrapper = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const HeaderTop = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 24px;
  }
`;

const HeaderText = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
`;

const H2 = styled(BaseH2)`
  font-size: 26px;
  line-height: 1.3;
`;

const Subtitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
  margin: 16px 0 0;
  padding: 0;
`;

const SubtitleMobile = styled(Subtitle)`
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

const SubtitleDesktop = styled(Subtitle)`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
  }
`;

const Illustration = styled.img`
  width: 58px;
  flex: 0 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 110px;
    order: 1;
  }
`;

const CloseBtn = styled.button`
  flex: 0 0 auto;
  order: 2;
  width: 40px;
  height: 40px;
  background: url(${closeIcon});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 50%;
  cursor: pointer;
  content: " ";
`;
CloseBtn.defaultProps = {
  type: "button",
};

const ColumnWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    gap: 24px;
  }
`;

const Column = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: flex-start;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: calc(100% / 3);
  }
`;

const ColumnIcon = styled.img`
  width: 40px;
`;

const ColumnText = styled.div``;

const ColumnTitle = styled(H4)`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
  margin-bottom: 4px;
`;

const ColumnBody = styled.p`
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: 1.5;
  margin: 0;
  padding: 0;
`;

const Link = styled(Anchor)`
  font-family: ${({ theme }) => theme.fonts.primary};
`;

function RoomSetup() {
  const [networkError, setNetworkError] = useState(false);
  const { room, setRoom } = useWaitingRoomContext();
  const { analyticsEvent } = useAnalyticsContext();
  const [
    title,
    body,
    freeTitle,
    freeBody,
    secureTitle,
    secureBody,
    appTitle,
    appBody,
    linkIos,
    linkAndroid,
    networkErrorText,
  ] = useCopy([
    "setup.hello",
    "setup.body",
    "setup.free.title",
    "setup.free.body",
    "setup.secure.title",
    "setup.secure.body",
    "setup.app.title",
    "setup.app.body",
    "setup.app.linkIos",
    "setup.app.linkAndroid",
    "setup.networkError",
  ]);
  const { roomAlias } = room;
  const show = !room.setupReady;

  const dismiss = async () => {
    // Dismiss regardless of network connectivity
    setRoom(state => {
      return { ...state, setupReady: true };
    });
    try {
      await requestUpdateRoom(roomAlias);
    } catch (err) {
      setNetworkError(true);
    }
  };

  const handleAppLinkClick = platform => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: `new-user-${platform}-app`,
    });
  };

  useEffect(() => {
    if (show) {
      analyticsEvent({
        eventName: "banner",
        actionType: "show",
        actionName: "room-setup",
      });
    }
  }, [show, analyticsEvent]);

  if (networkError) {
    return (
      <Wrapper>
        <ErrorAlert message={networkErrorText} />
      </Wrapper>
    );
  }

  // Don't need to show anything if user already setup
  if (!show) {
    return null;
  }

  return (
    <Wrapper>
      <Panel>
        <div>
          <HeaderWrapper>
            <HeaderTop>
              <Illustration src={mobileIllustration} />
              <HeaderText>
                <H2>
                  {swapCopyVariables(title, {
                    NAME: room.profileFirstName,
                  })}
                </H2>
                <SubtitleDesktop>{body}</SubtitleDesktop>
              </HeaderText>
              <CloseBtn onClick={dismiss} />
            </HeaderTop>
            <SubtitleMobile>{body}</SubtitleMobile>
          </HeaderWrapper>
          <ColumnWrapper>
            <Column>
              <ColumnIcon src={freeIcon} />
              <ColumnText>
                <ColumnTitle>{freeTitle}</ColumnTitle>
                <ColumnBody>{freeBody}</ColumnBody>
              </ColumnText>
            </Column>
            <Column>
              <ColumnIcon src={secureIcon} />
              <ColumnText>
                <ColumnTitle>{secureTitle}</ColumnTitle>
                <ColumnBody>{secureBody}</ColumnBody>
              </ColumnText>
            </Column>
            <Column>
              <ColumnIcon src={mobileIcon} />
              <ColumnText>
                <ColumnTitle>{appTitle}</ColumnTitle>
                <ColumnBody>
                  {swapCopyVariables(appBody, {
                    LINK_IOS: (
                      <Link
                        onClick={() => handleAppLinkClick("ios")}
                        href={PROFESSIONALS_APP_URL_IOS}
                        target="_blank"
                      >
                        {linkIos}
                      </Link>
                    ),
                    LINK_ANDROID: (
                      <Link
                        onClick={() => handleAppLinkClick("android")}
                        href={PROFESSIONALS_APP_URL_ANDROID}
                        target="_blank"
                      >
                        {linkAndroid}
                      </Link>
                    ),
                  })}
                </ColumnBody>
              </ColumnText>
            </Column>
          </ColumnWrapper>
        </div>
      </Panel>
    </Wrapper>
  );
}

export default RoomSetup;
