import React from "react";
import styled, { css } from "styled-components";
import usePublications from "../../hooks/usePublications";
import useTrack from "../../hooks/useTrack";
import useVideoContext from "../../hooks/useVideoContext";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import useParticipantInfo from "../../hooks/useParticipantInfo";
import AudioLevelIndicator from "../AudioLevelIndicator";
import screenShareImage from "../../assets/icon-active-screen-share.svg";

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  width: auto;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px 8px;
  background: ${({ theme }) => theme.colors.transparentGreyBackground};
  margin-right: 16px;
  border-radius: 8px;

  ${({ thumbnail }) =>
    thumbnail &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 5px;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.75) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      border-radius: 0;
      width: calc(100% - 10px);
    `};
`;

const DisplayName = styled.div`
  height: 20px;
  line-height: 20px;
  margin-left: 8px;
  color: white;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-family: ${({ theme }) => theme.fonts.semiBold};
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ScreenShareIcon = styled.img`
  padding-left: 8px;
`;

function ParticipantInfo({ participant, thumbnail }) {
  const { screenShareParticipant } = useVideoContext();
  const { displayName } = useParticipantInfo(participant.identity);
  const publications = usePublications(participant);
  const audioPublication = publications.find(p => p.kind === "audio");
  const audioTrack = useTrack(audioPublication);
  const isScreenShare =
    screenShareParticipant &&
    participant.identity === screenShareParticipant.identity;
  const [screenSharingName] = useCopy(["callscreen.presenter"]);

  const displayNameText = isScreenShare
    ? swapCopyVariables(screenSharingName, { NAME: displayName })
    : displayName;

  return (
    <Wrapper thumbnail={thumbnail}>
      <AudioLevelIndicator audioTrack={audioTrack} />
      <DisplayName>{displayNameText}</DisplayName>
      {isScreenShare && !thumbnail && (
        <ScreenShareIcon src={screenShareImage} alt={displayNameText} />
      )}
    </Wrapper>
  );
}

export default ParticipantInfo;
