import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import iosIcon from "../../assets/app-store-ios.svg";
import androidIcon from "../../assets/app-store-play.svg";
import {
  SESSIONS_APP_URL_IOS,
  SESSIONS_APP_URL_ANDROID,
  PROFESSIONALS_APP_URL_IOS,
  PROFESSIONALS_APP_URL_ANDROID,
} from "../../mobileApps";

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.light};
  border-radius: 8px;
  padding: 16px;
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
  margin: 0 0 4px;
`;

const Prompt = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
  margin: 0;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-top: 17px;
  align-items: flex-start;

  a {
    line-height: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 17px;
  margin-top: 17px;
  align-items: flex-start;

  a {
    line-height: 0;
  }
`;

export const Buttons = ({ app }) => {
  const [iosAltText, androidAltText] = useCopy([
    "appLinks.ios.alt",
    "appLinks.android.alt",
  ]);
  return (
    <ButtonsWrapper>
      <a
        href={
          app === "sessions" ? SESSIONS_APP_URL_IOS : PROFESSIONALS_APP_URL_IOS
        }
        target="_blank"
        rel="noreferrer"
      >
        <img src={iosIcon} alt={iosAltText} />
      </a>
      <a
        href={
          app === "sessions"
            ? SESSIONS_APP_URL_ANDROID
            : PROFESSIONALS_APP_URL_ANDROID
        }
        target="_blank"
        rel="noreferrer"
      >
        <img src={androidIcon} alt={androidAltText} />
      </a>
    </ButtonsWrapper>
  );
};

/**
 * @param string app App Name, one of "professionals" or "sessions"
 */
const AppStoreLinks = ({ app }) => {
  const [
    sessionsTitle,
    sessionsPrompt,
    professionalsTitle,
    professionalsPrompt,
    iosAltText,
    androidAltText,
  ] = useCopy([
    "appLinks.sessions.title",
    "appLinks.sessions.prompt",
    "appLinks.professionals.title",
    "appLinks.professionals.prompt",
    "appLinks.ios.alt",
    "appLinks.android.alt",
  ]);

  return (
    <Wrapper>
      <Title>{app === "sessions" ? sessionsTitle : professionalsTitle}</Title>
      <Prompt>
        {app === "sessions" ? sessionsPrompt : professionalsPrompt}
      </Prompt>
      <LinkWrapper>
        <a
          href={
            app === "sessions"
              ? SESSIONS_APP_URL_IOS
              : PROFESSIONALS_APP_URL_IOS
          }
          target="_blank"
          rel="noreferrer"
        >
          <img src={iosIcon} alt={iosAltText} />
        </a>
        <a
          href={
            app === "sessions"
              ? SESSIONS_APP_URL_ANDROID
              : PROFESSIONALS_APP_URL_ANDROID
          }
          target="_blank"
          rel="noreferrer"
        >
          <img src={androidIcon} alt={androidAltText} />
        </a>
      </LinkWrapper>
    </Wrapper>
  );
};

export default AppStoreLinks;
