import React, { useContext, useCallback } from "react";
import styled from "styled-components";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import { NotificationContext } from "./index";

const Wrapper = styled(Snackbar)`
  && {
    padding: 0;
    bottom: ${({ position }) => (position === "bottom" ? "110px" : "50%")};
    text-align: center;
    min-width: 0;
  }
  .MuiSnackbarContent-message {
    width: 100%;
    padding: 0;
  }
`;
const Content = styled(SnackbarContent)`
  && {
    padding: 5px 10px;
    background-color: ${({ theme }) => theme.colors.greyOverlay};
    min-width: 0;
    flex-grow: 0;
  }
`;

const Notification = ({ autoHideDuration, position, context }) => {
  const { currentMessages, closeMessage } = useContext(NotificationContext);
  const handleClose = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      closeMessage(context);
    },
    [closeMessage, context],
  );
  const currentMessage = currentMessages.find(m => m.context === context);

  let positionProps = {};
  switch (position) {
    case "center":
      positionProps = {
        sx: { height: "100%" },
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      };
      break;
    case "top":
      positionProps = {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      };
      break;
    case "bottom":
      positionProps = {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      };
      break;
    default:
  }

  return (
    <Wrapper
      open={!!currentMessage}
      autoHideDuration={autoHideDuration}
      transitionDuration={1500}
      onClose={handleClose}
      position={position}
      key={currentMessage ? currentMessage.id : undefined}
      {...positionProps}
    >
      <Content message={currentMessage ? currentMessage.message : null} />
    </Wrapper>
  );
};

Notification.defaultProps = {
  autoHideDuration: 3000,
  position: "bottom",
  context: "default",
};

export default Notification;
