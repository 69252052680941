import styled from "styled-components";
import { isMobile } from "@sussex/react-kit/utils";
import { Link } from "@sussex/react-kit/elements";
import useCopyRoomUrl from "../../hooks/useCopyRoomUrl";
import shareIcon from "../../assets/share.svg";
import linkIcon from "../../assets/link.svg";
import mailIcon from "../../assets/mail.svg";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  min-height: 37px;
  width: 100%;
`;

const Action = styled(Link)`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: 16px;
  line-height: 1.5;
`;

const Confirmation = styled.div`
  text-align: center;
  background-color: #696a6d;
  font-size: 14px;
  line-height: 1.5;
  color: white;
  padding: 8px 0;
  border-radius: 100px;
  width: 100%;
`;

const WaitingRoomShare = ({ onSendInvite }) => {
  const { getRoomUrl } = useWaitingRoomContext();
  const { copyURL, clicked: copyClicked } = useCopyRoomUrl();
  const roomUrl = getRoomUrl();
  const { analyticsEvent } = useAnalyticsContext();
  const [shareText, copyText, sendText, copyConfirmationText] = useCopy([
    "invite.shareTitle",
    "invite.copyTitle",
    "invite.emailTitle",
    "invite.copyConfirmation",
  ]);

  const handleCopy = () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "copy-waiting-room-link",
    });
    copyURL();
  };

  const handleShare = () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "share-waiting-room-link",
    });
    navigator.share({
      url: roomUrl,
    });
  };

  const handleSend = () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "email-waiting-room-link",
    });
    onSendInvite();
  };

  if (copyClicked) {
    return (
      <Wrapper>
        <Confirmation>{copyConfirmationText}</Confirmation>
      </Wrapper>
    );
  }

  if (isMobile) {
    return (
      <Wrapper>
        <Action onClick={handleShare}>
          <img src={shareIcon} alt="" />
          {shareText}
        </Action>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Action onClick={handleCopy}>
        <img src={linkIcon} alt="" />
        {copyText}
      </Action>
      <Action onClick={handleSend}>
        <img src={mailIcon} alt="" />
        {sendText}
      </Action>
    </Wrapper>
  );
};

export default WaitingRoomShare;
