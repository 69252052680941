// Ref: https://www.twilio.com/docs/api/errors
const TwilioErrors = {
  RoomCompleted: 53118,
  SignalingConnectionError: 53000,
  SignalingConnectionDisconnected: 53001,
  SignalingServerBusyError: 53006,
  RoomMaxParticipantsExceededError: 53105,
  RoomNotFoundError: 53106,
  MediaConnectionError: 53405,
};

// We are including the MediaConnectionError here because we've seen that
// when there are 2 participants in a p2p room, and one of the participants
// has a network issue which makes it disconnect from the room, the other
// participant is also disconnected from the room with this MediaConnectionError
const isSessionEndedCode = code => {
  return [
    TwilioErrors.RoomCompleted,
    TwilioErrors.MediaConnectionError,
  ].includes(code);
};

export default TwilioErrors;
export { isSessionEndedCode };
