import { useEffect } from "react";
import { isSessionEndedCode } from "../../../twilioErrors";

export default function useHandleRoomDisconnectionErrors(
  room,
  onError,
  removeLocalAudioTrack,
  removeLocalVideoTrack,
  isScreenSharing,
  toggleScreenShare,
  setSelectedParticipant,
) {
  useEffect(() => {
    const onDisconnected = (room, error) => {
      if (error && !isSessionEndedCode(error.code)) {
        onError(error);
      }

      removeLocalAudioTrack();
      removeLocalVideoTrack();
      if (isScreenSharing) {
        toggleScreenShare();
      }
      setSelectedParticipant(null);
    };

    room.on("disconnected", onDisconnected);
    return () => {
      room.off("disconnected", onDisconnected);
    };
  }, [
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isScreenSharing,
    toggleScreenShare,
    setSelectedParticipant,
  ]);
}
