import React from "react";
import styled, { css } from "styled-components";
import Modal from "../Modal";
import { Button } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";

const variants = {
  default: {
    body: css`
      padding: 50px 10px;
    `,
  },
  compact: {
    body: css`
      padding: 0;
    `,
  },
};
const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  button {
    width: 100%;
    margin: 0 5px;
  }
`;

const Body = styled.div`
  padding: 10 10px;
  text-align: center;
  font-size: 17px;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.default};
  ${({ variant }) => variant && variants[variant].body}
`;

export default function ConfirmationModal({
  title,
  onConfirm,
  onClose,
  onCancel,
  cancelText,
  confirmText,
  children,
  variant = "default",
  maxWidth = "450px",
}) {
  const [defaultCancelText, defaultConfirmText] = useCopy([
    "global.cancel",
    "global.confirm",
  ]);

  cancelText = cancelText ? cancelText : defaultCancelText;
  confirmText = confirmText ? confirmText : defaultConfirmText;

  const renderFooter = () => {
    return (
      <FooterWrapper>
        <Button
          variant="white"
          onClick={onCancel || onClose}
          id="confirmationModalCancel"
        >
          {cancelText}
        </Button>
        <Button onClick={onConfirm} id="confirmationModalConfirm">
          {confirmText}
        </Button>
      </FooterWrapper>
    );
  };

  return (
    <Modal
      title={title}
      close={onCancel}
      footer={renderFooter()}
      maxWidth={maxWidth}
    >
      <Body variant={variant}>{children}</Body>
    </Modal>
  );
}
