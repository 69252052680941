import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import backIcon from "../../assets/arrow-back-long.svg";
import { useAppStateContext, actions } from "../../providers/AppStateProvider";
import useVideoContext from "../../hooks/useVideoContext";

const { WAITROOM_SET_SETUP_CHECK } = actions;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: 21px;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  width: 75%;
`;

const BackButton = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = () => {
  const [titleText, backText] = useCopy(["setupCheck.title", "global.back"]);
  const { dispatch } = useAppStateContext();
  const { removeLocalVideoTrack, removeLocalAudioTrack } = useVideoContext();

  return (
    <Wrapper>
      <BackButton
        type="button"
        onClick={() => {
          removeLocalVideoTrack();
          removeLocalAudioTrack();
          dispatch({
            type: WAITROOM_SET_SETUP_CHECK,
            setupCheck: false,
          });
        }}
      >
        <img src={backIcon} alt={backText} />
      </BackButton>
      <Title>{titleText}</Title>
    </Wrapper>
  );
};

export default Header;
