import React, { useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import Muted from "./Muted";
import useIsTrackEnabled from "../../hooks/useIsTrackEnabled";
import useMediaStreamTrack from "../../hooks/useMediaStreamTrack";

const Svg = styled.svg`
  position: relative;
`;

const AudioContext = window.AudioContext || window.webkitAudioContext;

const initializeAnalyser = stream => {
  const audioContext = new AudioContext(); // create a new audioContext for each audio indicator
  if (!(audioContext && stream)) {
    return;
  }
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.5;
  analyser.fftSize = 1024;

  audioSource.connect(analyser);

  // Here we provide a way for the audioContext to be closed.
  // Closing the audioContext allows the unused audioSource to be garbage collected.
  // Errors can be ignored because they are thrown when the audioContext is already closed.
  stream.addEventListener("cleanup", () => {
    if (audioContext.state !== "closed") {
      audioContext.close().catch(e => {
        console.log(e);
      });
    }
  });

  return analyser;
};

const isIOS = /iPhone|iPad/.test(navigator.userAgent);

function AudioLevelIndicator({ audioTrack }) {
  const ref = useRef(null);
  const [analyser, setAnalyser] = useState(null);
  const isTrackEnabled = useIsTrackEnabled(audioTrack);
  const mediaStreamTrack = useMediaStreamTrack(audioTrack);

  const {
    colors: { brightGreen },
  } = useTheme();

  const updateRect = changeValues => {
    if (!ref.current) {
      return;
    }
    changeValues.forEach(({ y, height }, i) => {
      const rect = ref.current.getElementById(`ai${i}`);
      rect.setAttribute("y", y);
      rect.setAttribute("height", height);
    });
  };

  useEffect(() => {
    if (!audioTrack || !mediaStreamTrack || !isTrackEnabled) return;
    // Here we create a new MediaStream from a clone of the mediaStreamTrack. A
    // clone is created to allow multiple instances of this component for a
    // single AudioTrack on iOS Safari. We only clone the mediaStreamTrack on iOS.
    let newMediaStream = new MediaStream([
      isIOS ? mediaStreamTrack.clone() : mediaStreamTrack,
    ]);

    // Here we listen for the 'stopped' event on the audioTrack. When the
    // audioTrack is stopped, we stop the cloned track that is stored in
    // 'newMediaStream'. It is important that we stop all tracks when they are
    // not in use. Browsers like Firefox don't let you create a new stream from
    // a new audio device while the active audio device still has active tracks.
    const stopAllMediaStreamTracks = () => {
      if (isIOS) {
        // If we are on iOS, then we want to stop the MediaStreamTrack that we
        // have previously cloned. If we are not on iOS, then we do not stop the
        //  MediaStreamTrack since it is the original and still in use.
        newMediaStream.getTracks().forEach(track => track.stop());
      }
      newMediaStream.dispatchEvent(new Event("cleanup")); // Stop the audioContext
    };
    audioTrack.on("stopped", stopAllMediaStreamTracks);

    const reinitializeAnalyser = () => {
      stopAllMediaStreamTracks();
      // We only clone the mediaStreamTrack on iOS.
      newMediaStream = new MediaStream([
        isIOS ? mediaStreamTrack.clone() : mediaStreamTrack,
      ]);
      setAnalyser(initializeAnalyser(newMediaStream));
    };

    setAnalyser(initializeAnalyser(newMediaStream));

    // Fix Safari issue where the analysers stop functioning when the user
    // switches to a new tab and switches back to the app.
    window.addEventListener("focus", reinitializeAnalyser);

    return () => {
      stopAllMediaStreamTracks();
      window.removeEventListener("focus", reinitializeAnalyser);
      audioTrack.off("stopped", stopAllMediaStreamTracks);
    };
  }, [isTrackEnabled, mediaStreamTrack, audioTrack]);

  useEffect(() => {
    const SVGElement = ref.current;

    if (!isTrackEnabled || !SVGElement || !analyser) return;

    let animationFrameID;
    const render = () => {
      animationFrameID = window.requestAnimationFrame(() => {
        const array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        let values = 0;

        const length = array.length;
        for (let i = 0; i < length; i++) {
          values += array[i];
        }

        const volume = Math.round(values / length);

        switch (true) {
          case volume > 65:
            updateRect([
              { height: 11, y: 2 },
              { height: 15, y: 0 },
              { height: 11, y: 2 },
            ]);
            break;
          case volume > 45:
            updateRect([
              { height: 9, y: 3 },
              { height: 15, y: 0 },
              { height: 9, y: 3 },
            ]);
            break;
          case volume > 25:
            updateRect([
              { height: 9, y: 3 },
              { height: 13, y: 1 },
              { height: 9, y: 3 },
            ]);
            break;
          case volume > 15:
            updateRect([
              { height: 7, y: 4 },
              { height: 11, y: 2 },
              { height: 7, y: 4 },
            ]);
            break;
          default:
            updateRect([
              { height: 5, y: 5 },
              { height: 5, y: 5 },
              { height: 5, y: 5 },
            ]);
        }
        render();
      });
    };

    render();

    return () => {
      SVGElement.setAttribute("y", "21");
      window.cancelAnimationFrame(animationFrameID);
    };
  }, [isTrackEnabled, analyser]);

  return isTrackEnabled ? (
    <Svg
      ref={ref}
      width="20"
      height="15"
      viewBox="0 0 20 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        id="ai0"
        x="0"
        y="3"
        width="5"
        height="8"
        rx="2.5"
        fill={brightGreen}
      />
      <rect
        id="ai1"
        x="7"
        y="0"
        width="5"
        height="15"
        rx="2.5"
        fill={brightGreen}
      />
      <rect
        id="ai2"
        x="14"
        y="3"
        width="5"
        height="8"
        rx="2.5"
        fill={brightGreen}
      />
    </Svg>
  ) : (
    <Muted />
  );
}

export default React.memo(AudioLevelIndicator);
