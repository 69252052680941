import React from "react";
import styled from "styled-components";
import { Lightswitch, Label } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  margin-left: 8px;
  font-size: 16px;
  line-height: 1.5;
`;

function ChatSettings({ available, onChange }) {
  const [title, label] = useCopy([
    "settings.chat",
    "settings.availabilityToggle",
  ]);

  const handleAvailabilityChange = () => {
    onChange({ available: !available });
  };

  return (
    <>
      <Label>{title}</Label>
      <ToggleWrapper>
        <Lightswitch
          checked={available}
          onChange={handleAvailabilityChange}
          id="therapistAvailabilityToggle"
          size="large"
        />
        <ToggleLabel>{label}</ToggleLabel>
      </ToggleWrapper>
    </>
  );
}

export default ChatSettings;
