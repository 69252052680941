import React from "react";
import styled, { css } from "styled-components";
import { Button } from "@sussex/react-kit/elements";
import startSpinner from "../../assets/start-spinner.svg";
import useCopy from "../../hooks/useCopy";

const Btn = styled(Button)`
  min-width: 0;
  font-size: 14px;
  position: relative;
  height: 48px;
  border-radius: 48px;
`;

const Text = styled.span`
  ${({ loading }) =>
    loading &&
    css`
      visibility: hidden;
    `}
`;

const Spinner = styled.img`
  display: none;
  ${({ loading }) =>
    loading &&
    css`
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`;

function StartButton({ onClick, participantId, disabled, loading }) {
  const [joinText] = useCopy(["clientlist.join"]);
  return (
    <Btn
      onClick={onClick}
      variant="blue"
      id={`startSession-${participantId}`}
      disabled={disabled}
      loading={loading ? 1 : 0}
    >
      <Text loading={loading ? 1 : 0}>{joinText}</Text>
      <Spinner loading={loading ? 1 : 0} src={startSpinner} />
    </Btn>
  );
}

export default StartButton;
