import React, { useEffect } from "react";
import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { Anchor } from "@sussex/react-kit/elements";
import Header from "../components/Header";
import { useWaitingRoomContext } from "../providers/WaitingRoomProvider";
import { WaitingRoomProvider } from "../providers/WaitingRoomProvider";
import { useSetPage } from "../providers/AnalyticsProvider";
import WaitingRoomDetails from "../components/WaitingRoomDetails";
import useCopy from "../hooks/useCopy";
import Panel from "../components/Panel";
import AppStoreLinks from "../components/AppStoreLinks";
import { datadogSetUserType } from "../datadog";

const Body = styled.div`
  max-width: 875px;
  margin: 140px 15px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 75%;
    margin: 0 auto;
  }
`;

const Link = styled(Anchor)`
  font-family: ${({ theme }) => theme.fonts.primary};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Providers = ({ children }) => (
  <WaitingRoomProvider>
    <WaitingRoomDetails type="client">{children}</WaitingRoomDetails>
  </WaitingRoomProvider>
);

const MessageWrapper = styled.div`
  h3 {
    font-size: 26px;
    line-height: 1;
    margin: 0 0 3px;
    font-family: ${({ theme }) => theme.fonts.semiBold};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: 26px;
    }
  }

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize.large};
    line-height: 1.5;
  }
`;

const View = () => {
  const { getRoomUrl } = useWaitingRoomContext();
  useSetPage("SessionComplete");
  const [thankYouText, sessionTerminatedText, returnText] = useCopy([
    "exitscreen.thankyou",
    "exitscreen.sessionTerminated",
    "exitscreen.sessionTerminatedReturnLink",
  ]);

  useEffect(() => {
    datadogSetUserType("client");
  }, []);

  return (
    <div>
      <Header type="logoOnly" />
      <Body>
        <Panel>
          <Content>
            <MessageWrapper>
              <h3>{thankYouText}</h3>
              <p>
                {swapCopyVariables(sessionTerminatedText, {
                  RETURN_LINK: <Link href={getRoomUrl()}>{returnText}</Link>,
                })}
              </p>
            </MessageWrapper>
            <AppStoreLinks app="sessions" />
          </Content>
        </Panel>
      </Body>
    </div>
  );
};
export default function ClientExitRoomView() {
  return (
    <Providers>
      <View />
    </Providers>
  );
}
