import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import SettingsView from "./SettingsView";
import HistoryView from "./HistoryView";
import TherapistRoomView from "./TherapistRoomView";
import Authenticated from "../components/Authenticated";
import DesktopNotification from "../components/DesktopNotification";
import { WaitingRoomProvider } from "../providers/WaitingRoomProvider";
import WaitingRoomDetails from "../components/WaitingRoomDetails";
import Websocket from "../components/Websocket";
import SidebarLayout from "../components/SidebarLayout";
import SlideoutLayout from "../components/SlideoutLayout";
import { ChatProvider } from "../components/Chat";
import HelpView from "./HelpView";
import EventBus from "../eventBus";
import { datadogSetUserType } from "../datadog";
import AppInstallBanner from "../components/AppInstallBanner";
import usePollParticipants from "../hooks/usePollParticipants";
import { NavProvider } from "../providers/NavProvider/NavProvider";

const Admin = () => {
  useEffect(() => {
    datadogSetUserType("therapist");
  }, []);
  usePollParticipants();

  return (
    <Authenticated>
      <NavProvider>
        <DesktopNotification />
        <ChatProvider eventBus={EventBus}>
          <WaitingRoomProvider>
            <WaitingRoomDetails type="therapist">
              <Websocket isTherapist={true} />
              <SlideoutLayout>
                <SidebarLayout isTherapist={true}>
                  <Routes>
                    <Route path="/" element={<TherapistRoomView />} />
                    <Route path="/settings" element={<SettingsView />} />
                    <Route path="/history" element={<HistoryView />} />
                    <Route path="/help" element={<HelpView />} />
                  </Routes>
                </SidebarLayout>
              </SlideoutLayout>
              <AppInstallBanner app="professionals" />
            </WaitingRoomDetails>
          </WaitingRoomProvider>
        </ChatProvider>
      </NavProvider>
    </Authenticated>
  );
};

export default Admin;
