import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAudioAlertContext } from "../../providers/AudioAlertProvider";
import { useAppStateContext } from "../../providers/AppStateProvider";
import ClientItem from "./ClientItem";
import GhostRow from "./GhostRow";

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ItemWrapper = styled.div`
  background: ${({ theme }) => theme.colors.light};
  border-radius: 100px;
  padding: 8px;
  overflow: hidden;
`;

function ClientList({
  chatDisabled,
  startDisabled,
  startSession,
  loadingIdentity,
}) {
  const [seenParticipants, setSeenParticipants] = useState([]);
  const {
    state: { waitingRoom },
  } = useAppStateContext();
  const { participants: waitingParticipants } = waitingRoom;
  const { playChime } = useAudioAlertContext();

  // this ref tracks participants as "seen" when they show up to a room
  useEffect(() => {
    const newUnseenParticipants = waitingParticipants.filter(
      p =>
        !seenParticipants.map(s => s.participantId).includes(p.participantId),
    );
    if (newUnseenParticipants.length > 0) {
      setSeenParticipants(prev => prev.concat(newUnseenParticipants));
      playChime();
    }
  }, [waitingParticipants, seenParticipants, playChime]);

  const isEmpty = waitingParticipants.length === 0;

  const renderListElement = (p, index) => {
    return (
      <ItemWrapper key={p.participantId}>
        <ClientItem
          participant={p}
          onStart={() => startSession(p)}
          startDisabled={startDisabled || isEmpty || loadingIdentity}
          loading={loadingIdentity === p.participantId}
          chatVisible={true}
          chatDisabled={chatDisabled || isEmpty || loadingIdentity}
          index={index}
        />
      </ItemWrapper>
    );
  };

  return (
    <List>
      {isEmpty ? (
        <ItemWrapper>
          <GhostRow />
        </ItemWrapper>
      ) : (
        waitingParticipants.map(renderListElement)
      )}
    </List>
  );
}

export default ClientList;
