import React from "react";
import styled, { css } from "styled-components";

const sizes = {
  small: 30,
  medium: 40,
  large: 48,
};

const Image = styled.img`
  height: ${({ variant }) => `${sizes[variant]}px`};
  width: ${({ variant }) => `${sizes[variant]}px`};
  border-radius: 25px;
  object-fit: cover;
`;

const BadgeWrapper = styled.div`
  width: ${({ variant }) => `${sizes[variant]}px`};
  height: ${({ variant }) => `${sizes[variant]}px`};
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

const Badge = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  background: ${({ available, theme }) =>
    available ? theme.colors.green : theme.colors.red};
  margin-left: -15px;
`;

const InitialCircle = styled.div`
  height: ${({ variant }) => `${sizes[variant]}px`};
  width: ${({ variant }) => `${sizes[variant]}px`};
  border-radius: 25px;
  background: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.colors.gray};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Initials = styled.div`
  font-size: 14px;
  line-height: 1;
  height: 14px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  color: ${({ textColor, theme }) =>
    textColor ? textColor : theme.colors.black};
`;

const PeopleContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 15px;
`;

const MultiPersonWrapper = styled.div`
  margin-left: -15px;
  height: ${({ variant }) => `${sizes[variant]}px`};
  width: ${({ variant }) => `${sizes[variant]}px`};
  height: 40px;
  ${({ border, theme }) =>
    border &&
    css`
      border: 1px solid ${theme.colors.light};
      border-radius: 25px;
    `};
`;

const initials = (name = "") => {
  return name
    .trim()
    .split(" ")
    .filter(str => str.trim().length > 0)
    .map(str => str[0].toUpperCase())
    .filter((str, i, arr) => i === 0 || i === arr.length - 1)
    .join("");
};

const PersonCircleBase = ({
  imageURL,
  name,
  variant = "medium",
  backgroundColor,
  textColor,
}) => {
  return imageURL ? (
    <Image variant={variant} src={imageURL} />
  ) : (
    <InitialCircle variant={variant} backgroundColor={backgroundColor}>
      <Initials textColor={textColor}>{initials(name)}</Initials>
    </InitialCircle>
  );
};

const PersonCircleWithBadge = ({
  available = false,
  variant = "large",
  ...rest
}) => {
  return (
    <BadgeWrapper variant={variant}>
      <PersonCircleBase variant={variant} {...rest} />
      <Badge available={available} />
    </BadgeWrapper>
  );
};

const PersonCircle = ({
  people = null,
  backgroundColors = [],
  border = false,
  variant,
  ...rest
}) => {
  if (people) {
    return (
      <PeopleContainer>
        {people.map((p, i) => {
          const backgroundColor = backgroundColors[i] || null;
          return (
            <MultiPersonWrapper
              key={p.imageURL}
              border={border}
              variant={variant}
            >
              <PersonCircleBase
                {...p}
                {...rest}
                backgroundColor={backgroundColor}
              />
            </MultiPersonWrapper>
          );
        })}
      </PeopleContainer>
    );
  }
  return <PersonCircleBase variant={variant} {...rest} />;
};

export default PersonCircle;
export { PersonCircleWithBadge };
