import React from "react";
import ReactDOM from "react-dom";
import Router from "./router";
import CookieBot from "react-cookiebot";
import { Normalize } from "styled-normalize";
import { datadogInit } from "./datadog";

const cookiebotGroupId = "332cddcd-264e-4005-a7e9-ca8d4ecbb87f";

datadogInit();

ReactDOM.render(
  <>
    <React.StrictMode>
      <Normalize />
      <Router />
    </React.StrictMode>
    <CookieBot domainGroupId={cookiebotGroupId} />
  </>,
  document.getElementById("root"),
);
