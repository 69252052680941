import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import backIcon from "../../assets/arrow-back-long.svg";
import PersonCircle from "../PersonCircle";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 40px;
  margin-bottom: 16px;
`;

const BackButton = styled.button`
  display: flex;
  appearance: none;
  border: none;
  padding: 0;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  background-color: white;
  background-image: url("${backIcon}");
  background-size: 17px 12px;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  cursor: pointer;
`;

const NameWrapper = styled.div`
  overflow: hidden;
`;

const Name = styled.div`
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 1.3;
`;

const Header = ({ name, photoUrl, onBack }) => {
  const [title] = useCopy(["waitingRoom.roomTitle"]);

  return (
    <Wrapper>
      <BackButton onClick={onBack} type="button" />
      <PersonCircle imageURL={photoUrl} name={name} />
      <NameWrapper>
        <Name>{name}</Name>
        <Title>{title}</Title>
      </NameWrapper>
    </Wrapper>
  );
};

export default Header;
