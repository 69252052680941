import React from "react";
import styled from "styled-components";
import hamburgerIcon from "../../assets/hamburger-icon.svg";
import useCopy from "../../hooks/useCopy";

const Dot = styled.div`
  background: ${({ theme }) => theme.colors.light};
  box-sizing: border-box;
  width: 5px;
  height: 5px;
  border-radius: 50%;
`;

const Dots = styled.div`
  & > * {
    margin: 2px 0;
  }
`;

const Wrapper = styled.div`
  cursor: pointer;
  padding: 0 10px 5px;
  width: 10px;
`;

export default function OverflowMenu({ type, ...rest }) {
  const [menuText] = useCopy(["global.menu"]);
  return (
    <Wrapper id="video-settings-overflow-menu" {...rest}>
      {type === "hamburger" ? (
        <img src={hamburgerIcon} alt={menuText} />
      ) : (
        <Dots>
          <Dot></Dot>
          <Dot></Dot>
          <Dot></Dot>
        </Dots>
      )}
    </Wrapper>
  );
}
