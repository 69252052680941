const ADD_PARTICIPANT_INFO_LOADING = "ADD_PARTICIPANT_INFO_LOADING";
const ADD_PARTICIPANT_INFO_SUCCESS = "ADD_PARTICIPANT_INFO_SUCCESS";
const ADD_PARTICIPANT_INFO_FAILURE = "ADD_PARTICIPANT_INFO_FAILURE";
const WEBSOCKET_SET_CONNECTED = "WEBSOCKET_SET_CONNECTED";
const WEBSOCKET_SET_CONNECT = "WEBSOCKET_SET_CONNECT";
const WEBSOCKET_SET_ERROR = "WEBSOCKET_SET_ERROR";
const SESSION_START = "SESSION_START";
const SESSION_END = "SESSION_END";
const WAITROOM_SET_PARTICIPANTS = "WAITROOM_SET_PARTICIPANTS";
const WAITROOM_SET_DISPLAYNAME = "WAITROOM_SET_DISPLAYNAME";
const WAITROOM_SET_THERAPIST_AVAILABLE = "WAITROOM_SET_THERAPIST_AVAILABLE";
const WAITROOM_SET_SETUP_CHECK = "WAITROOM_SET_SETUP_CHECK";

export {
  ADD_PARTICIPANT_INFO_SUCCESS,
  ADD_PARTICIPANT_INFO_FAILURE,
  ADD_PARTICIPANT_INFO_LOADING,
  WAITROOM_SET_PARTICIPANTS,
  WAITROOM_SET_DISPLAYNAME,
  WAITROOM_SET_SETUP_CHECK,
  WEBSOCKET_SET_CONNECT,
  WEBSOCKET_SET_CONNECTED,
  WEBSOCKET_SET_ERROR,
  SESSION_START,
  SESSION_END,
  WAITROOM_SET_THERAPIST_AVAILABLE,
};
