import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import { useSessionHistoryContext } from "./SessionHistoryProvider";
import { Button, H2 } from "@sussex/react-kit/elements";

const Message = styled.div`
  text-align: center;
  padding: 50px;
  > * {
    margin-bottom: 10px;
    &:last-child: {
      margin-bottom: 0;
    }
  }
`;

const EmptyResultsView = () => {
  const [messageHeader, message, buttonText] = useCopy([
    "sessionHistory.noResults.header",
    "sessionHistory.noResults.message",
    "sessionHistory.noResults.clearFiltersMessage",
  ]);

  const { setStartFilter, setNameFilter, setOffset } =
    useSessionHistoryContext();

  const clearFilters = () => {
    setStartFilter("");
    setNameFilter("");
    setOffset("0");
  };

  return (
    <Message>
      <H2>{messageHeader}</H2>
      <div>{message}</div>
      <Button variant="white" onClick={clearFilters}>
        {buttonText}
      </Button>
    </Message>
  );
};

export default EmptyResultsView;
