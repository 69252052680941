import styled from "styled-components";

const Counter = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default Counter;
