import styled from "styled-components";
import { Button } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import useCopy from "../../hooks/useCopy";
import WaitingRoomBackground from "../WaitingRoomBackground";
import CenterLayout from "../CenterLayout";
import NativeAppBanner from "../NativeAppBanner";

const Wrapper = styled.div`
  background: white;
  box-shadow: ${({ theme }) => theme.panel.boxShadow};
  border: ${({ theme }) => theme.panel.border};
  border-radius: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row-reverse;
  }
`;

const Item = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50%;
  }
`;

const TextContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: 26px;
  line-height: 1.3;
  text-align: center;
`;

const Instructions = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
`;

const StartButton = styled(Button)`
  margin: 16px 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 0 0 40px;
  }
`;

const ClientWelcome = ({ onNext, sessionEnded }) => {
  const {
    room: { therapistDisplayName, therapistPhotoUrl },
  } = useWaitingRoomContext();
  const [title, instructions, next] = useCopy([
    "checkinscreen.welcome",
    "checkinscreen.instructions",
    "checkinscreen.continue",
  ]);

  return (
    <CenterLayout>
      {sessionEnded ? <NativeAppBanner /> : null}
      <Wrapper>
        <Item>
          <WaitingRoomBackground
            photo={therapistPhotoUrl}
            name={therapistDisplayName}
          />
        </Item>
        <Item>
          <TextContent>
            <Title>{title}</Title>
            <Instructions>
              {swapCopyVariables(instructions, {
                NAME: therapistDisplayName,
              })}
            </Instructions>
            <StartButton onClick={onNext} full round>
              {next}
            </StartButton>
          </TextContent>
        </Item>
      </Wrapper>
    </CenterLayout>
  );
};

export default ClientWelcome;
