import React from "react";
import { Link } from "@sussex/react-kit/elements";
import useCopyRoomUrl from "../../hooks/useCopyRoomUrl";

const CopyRoomUrl = ({ copyLinkText, copiedToClipboardText }) => {
  const { copyURL, clicked } = useCopyRoomUrl();

  const copyLink = () => {
    copyURL();
  };

  return (
    <Link onClick={copyLink}>
      {clicked ? copiedToClipboardText : copyLinkText}
    </Link>
  );
};

export default CopyRoomUrl;
