import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import chatIcon from "../../assets/chat-icon-outline.svg";
import { useChatContext } from "../Chat";
import Tooltip from "@mui/material/Tooltip";

const Btn = styled.button`
  appearance: none;
  background: white;
  border: none;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 0 0 auto;
  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
`;

const Badge = styled.div`
  position: absolute;
  top: -2px;
  left: 0px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.red};
  z-index: 1;
`;

function ChatButton({ channel, disabled }) {
  const { activeChannel, setActiveChannel, getUnreadCount } = useChatContext();
  const [chatText] = useCopy(["sessioncontrols.chat"]);

  const unreadCount = getUnreadCount(channel);

  const onClick = () => {
    // Set the active channel after the slideout panel invokes the onClose of the replaced chat panel.
    setActiveChannel("");

    if (activeChannel !== channel) {
      setTimeout(() => {
        setActiveChannel(channel);
      });
    }
  };

  return (
    <Tooltip title={chatText}>
      <Btn
        onClick={onClick}
        variant="blue"
        id={`chat-${channel}`}
        disabled={disabled}
      >
        {unreadCount > 0 && <Badge />}
        <img src={chatIcon} alt={chatText} />
      </Btn>
    </Tooltip>
  );
}

export default ChatButton;
