import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { requestSetAvailability } from "../../api/httpapi";
import useCopy from "../../hooks/useCopy";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { Lightswitch } from "@sussex/react-kit/elements";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";

const Wrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSize.normal};
  display: flex;
  align-items: center;
  input {
    margin-right: 3px;
  }
`;

export default function AvailableToggle() {
  const { state } = useAppStateContext();
  const [loading, setLoading] = useState(false);
  const [toggleStatusText] = useCopy(["chat.therapist.setAvailability"]);
  const [currentlyAvailable, setCurrentlyAvailable] = useState(
    state.waitingRoom.therapistAvailable,
  );
  const { analyticsEvent } = useAnalyticsContext();

  const toggleAvailability = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    analyticsEvent({
      eventName: "user_action",
      actionType: "toggle",
      actionName: currentlyAvailable
        ? "waiting-room-chat-off"
        : "waiting-room-chat-on",
    });

    setCurrentlyAvailable(!currentlyAvailable);
    try {
      await requestSetAvailability(!currentlyAvailable);
    } catch (error) {
      setCurrentlyAvailable(currentlyAvailable);
      setLoading(false);
    }
  }, [loading, currentlyAvailable, analyticsEvent]);

  // After a user clicks the toggle, we need to immediately transition the
  // lightswitch to the new state, but also prevent another toggle until the
  // updated state is received over the websocket and dispatched. To accomplish
  // this, we clone the initial state into a local state variable, then use the
  // following two useEffects to keep the state synchronized, and disable the
  // loading state once synchronized.
  useEffect(() => {
    setCurrentlyAvailable(state.waitingRoom.therapistAvailable);
  }, [state.waitingRoom.therapistAvailable]);

  useEffect(() => {
    if (
      loading &&
      currentlyAvailable === state.waitingRoom.therapistAvailable
    ) {
      setLoading(false);
    }
  }, [
    loading,
    setLoading,
    currentlyAvailable,
    state.waitingRoom.therapistAvailable,
  ]);

  return (
    <Wrapper>
      <Lightswitch
        checked={currentlyAvailable}
        onChange={toggleAvailability}
        id="therapistAvailabilityToggle"
        disabled={loading}
      />
      {toggleStatusText}
    </Wrapper>
  );
}
