import React, { useEffect, useRef } from "react";

const Heartbeat = ({ heartbeatInterval, heartbeatFunction }) => {
  const interval = useRef(null);

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }

    interval.current = setInterval(() => {
      heartbeatFunction();
    }, heartbeatInterval);

    return () => {
      clearInterval(interval.current);
    };
  }, [heartbeatInterval, heartbeatFunction]);

  return <></>;
};

export default Heartbeat;
