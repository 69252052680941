import { useCallback, useState, useRef, useEffect } from "react";
import EventEmitter from "events";
import Video from "twilio-video";
import { isMobile } from "@sussex/react-kit/utils";
import { desktopOptions, mobileOptions } from "../../../connectionOptions";

window.TwilioVideo = Video;

export default function useRoom(localTracks, onError) {
  const [room, setRoom] = useState(new EventEmitter());
  const [isConnecting, setIsConnecting] = useState(false);
  const options = isMobile ? mobileOptions : desktopOptions;
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const connect = useCallback(
    token => {
      setIsConnecting(true);

      return Video.connect(token, { ...options, tracks: localTracks })
        .then(newRoom => {
          setRoom(newRoom);

          const disconnect = () => {
            newRoom.disconnect();
          };

          newRoom.once("disconnected", () => {
            // Reset the room only after all other `disconnected` listeners
            // have been called.
            setTimeout(() => isMounted.current && setRoom(new EventEmitter()));
            window.removeEventListener("beforeunload", disconnect);
            if (isMobile) {
              window.removeEventListener("pagehide", disconnect);
            }
          });

          window.twilioRoom = newRoom;

          // Add a listener to disconnect from the room when a user closes
          // their browser
          window.addEventListener("beforeunload", disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user
            // closes their browser
            window.addEventListener("pagehide", disconnect);
          }
        })
        .catch(onError)
        .then(() => setIsConnecting(false));
    },
    [options, onError, localTracks],
  );

  return { room, isConnecting, connect };
}
