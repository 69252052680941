import { H2 } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  text-align: center;
`;

const Title = styled(H2)`
  font-size: 21px;
  line-height: 1.5;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.5;
`;

function CheckedInView() {
  const { room } = useWaitingRoomContext();
  const [titleText, bodyText] = useCopy([
    "checkinscreen.thanks",
    "checkinscreen.holdTight",
  ]);

  return (
    <Wrapper>
      <Title>{titleText}</Title>
      <Text>
        {swapCopyVariables(bodyText, { NAME: room.therapistDisplayName })}
      </Text>
    </Wrapper>
  );
}

export default CheckedInView;
