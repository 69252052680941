import Video, { LocalDataTrack } from "twilio-video";
import { useEffect, useState, useCallback } from "react";
import { isMobile } from "@sussex/react-kit/utils";
import {
  desktopVideoConfig,
  mobileVideoConfig,
} from "../../../connectionOptions";

export const LocalTrackErrors = {
  InUse: 0,
  Unavailable: 1,
  Permissions: 2,
};

const isAudioError = err => {
  return err && (err.device === "audio" || err.device === "both");
};

const isVideoError = err => {
  return err && (err.device === "video" || err.device === "both");
};

const getDeviceForError = (err, failingDevice) => {
  const otherDevice = failingDevice === "audio" ? "video" : "audio";
  return err && (err.device === "both" || err.device === otherDevice)
    ? "both"
    : failingDevice;
};

const getNextError = (err, workingDevice) => {
  if (!err || err.device === workingDevice) {
    return null;
  }
  const otherDevice = workingDevice === "audio" ? "video" : "audio";
  return {
    ...err,
    device: otherDevice,
  };
};

export default function useLocalTracks() {
  const [dataTrack] = useState(new LocalDataTrack());
  const [audioTrack, setAudioTrack] = useState(undefined);
  const [videoTrack, setVideoTrack] = useState(undefined);
  const [localTrackError, setLocalTrackError] = useState(undefined);
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(true);
  const [localVideoOptions, setLocalVideoOptions] = useState({});

  const handleTrackError = useCallback((device, err) => {
    let code;
    switch (err.name) {
      case "NotReadableError":
      case "AbortError":
        console.log("Device in use error", err);
        code = LocalTrackErrors.InUse;
        break;
      case "OverconstrainedError":
        console.log("Device unavailable error", err);
        code = LocalTrackErrors.Unavailable;
        break;
      default:
        console.log("Media Permissions Error", err);
        code = LocalTrackErrors.Permissions;
    }
    setLocalTrackError({
      device,
      error: err,
      code,
    });
  }, []);

  const createLocalAudioTrack = useCallback(
    (options = {}) => {
      return Video.createLocalAudioTrack(options)
        .then(newTrack => {
          if (isAudioError(localTrackError)) {
            setLocalTrackError(getNextError(localTrackError, "audio"));
          }
          setAudioTrack(newTrack);
          return newTrack;
        })
        .catch(err => {
          handleTrackError(getDeviceForError(localTrackError, "audio"), err);
        });
    },
    [localTrackError, handleTrackError],
  );

  const createLocalVideoTrack = useCallback(
    (allOptions = {}) => {
      const { publish = true, ...newOptions } = allOptions;

      const options = {
        ...localVideoOptions,
        ...newOptions,
        name: `camera-${Date.now()}`,
      };

      return Video.createLocalVideoTrack(options)
        .then(newTrack => {
          if (isVideoError(localTrackError)) {
            setLocalTrackError(getNextError(localTrackError, "video"));
          }
          const settings = newTrack.mediaStreamTrack.getSettings();
          options.deviceId = {
            exact: settings.deviceId,
          };
          options.groupId = {
            ideal: settings.groupId,
          };
          setLocalVideoOptions(options);
          if (publish) {
            setVideoTrack(newTrack);
          }
          return newTrack;
        })
        .catch(err => {
          handleTrackError(getDeviceForError(localTrackError, "video"), err);
        });
    },
    [localTrackError, handleTrackError, localVideoOptions],
  );
  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      const mediaElements = audioTrack.detach();
      mediaElements.forEach(mediaElement => mediaElement.remove());
      setAudioTrack(undefined);
    }
  }, [audioTrack, setAudioTrack]);
  useEffect(
    () => () => {
      removeLocalAudioTrack();
    },
    [removeLocalAudioTrack],
  );

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack, setVideoTrack]);

  useEffect(
    () => () => {
      removeLocalVideoTrack();
    },
    [removeLocalVideoTrack],
  );

  const replaceLocalAudioTrack = useCallback(
    ({ deviceId = "", groupId = "" }) => {
      const device = deviceId ? { deviceId: { exact: deviceId } } : {};
      const group = groupId ? { groupId: { ideal: groupId } } : {};
      const options = {
        ...device,
        ...group,
      };

      if (!audioTrack) {
        if (isAudioError(localTrackError)) {
          createLocalAudioTrack(options);
        }
        return null;
      }

      return audioTrack
        .restart(options)
        .then(() => {
          if (isAudioError(localTrackError)) {
            setLocalTrackError(getNextError(localTrackError, "audio"));
          }
        })
        .catch(err => {
          handleTrackError(getDeviceForError(localTrackError, "audio"), err);
          throw err;
        });
    },
    [audioTrack, createLocalAudioTrack, handleTrackError, localTrackError],
  );

  const replaceLocalVideoTrack = useCallback(
    ({ deviceId = "", groupId = "", dimensions = {} }) => {
      const device = deviceId ? { deviceId: { exact: deviceId } } : {};
      const group = groupId ? { groupId: { ideal: groupId } } : {};
      const options = {
        ...localVideoOptions,
        ...device,
        ...group,
        ...dimensions,
        name: `camera-${Date.now()}`,
      };

      if (!videoTrack) {
        if (isVideoError(localTrackError)) {
          createLocalVideoTrack(options);
        } else {
          setLocalVideoOptions(options);
        }
        return null;
      }

      return videoTrack
        .restart(options)
        .then(() => {
          if (isVideoError(localTrackError)) {
            setLocalTrackError(getNextError(localTrackError, "video"));
          }
          setLocalVideoOptions(options);
        })
        .catch(err => {
          handleTrackError(getDeviceForError(localTrackError, "video"), err);
          throw err;
        });
    },
    [
      videoTrack,
      createLocalVideoTrack,
      handleTrackError,
      localTrackError,
      localVideoOptions,
    ],
  );

  // Get local video and audio tracks with default settings.
  useEffect(() => {
    const defaultVideoOptions = {
      ...(isMobile ? mobileVideoConfig : desktopVideoConfig),
      name: `camera-${Date.now()}`,
    };
    Video.createLocalTracks({
      video: defaultVideoOptions,
      audio: true,
    })
      .then(tracks => {
        const vTrack = tracks.find(track => track.kind === "video");
        const aTrack = tracks.find(track => track.kind === "audio");
        if (vTrack) {
          const vSettings = vTrack.mediaStreamTrack.getSettings();
          setLocalVideoOptions({
            ...defaultVideoOptions,
            deviceId: {
              exact: vSettings.deviceId,
            },
            groupId: {
              ideal: vSettings.groupId,
            },
          });
          setVideoTrack(vTrack);
        }
        if (aTrack) {
          setAudioTrack(aTrack);
        }
      })
      .catch(err => {
        setLocalVideoOptions(defaultVideoOptions);
        handleTrackError("both", err);
      })
      .finally(() => setIsAcquiringLocalTracks(false));
  }, [handleTrackError]);

  const localTracks = [audioTrack, videoTrack, dataTrack].filter(
    track => track !== undefined,
  );

  return {
    localTracks,
    dataTrack,
    createLocalAudioTrack,
    removeLocalAudioTrack,
    createLocalVideoTrack,
    isAcquiringLocalTracks,
    removeLocalVideoTrack,
    replaceLocalVideoTrack,
    replaceLocalAudioTrack,
    localVideoOptions,
    localTrackError,
  };
}
