import styled from "styled-components";
import {
  SubmitButton,
  TextInputLabel,
  Link,
  Checkbox,
} from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import { LoadingBounce } from "@sussex/react-kit/assets";
import useCopy from "../../hooks/useCopy";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
`;

const CheckWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const CheckInForm = ({
  onSubmit,
  displayName,
  onDisplayNameChange,
  disabled,
  tosAccepted,
  toggleAccepted,
  loading,
}) => {
  const [
    tosPromptText,
    enterNameText,
    joinSessionText,
    termsText,
    termsLinkText,
    privacyLinkText,
  ] = useCopy([
    "checkinscreen.tosPrompt",
    "checkinscreen.enterName",
    "checkinscreen.joinSession",
    "checkinscreen.terms",
    "checkinscreen.termLink",
    "checkinscreen.privacyLink",
  ]);

  const openTOS = () => document.dispatchEvent(new Event("tos"));
  const openPrivacy = () => document.dispatchEvent(new Event("privacy-policy"));

  return (
    <Form onSubmit={onSubmit}>
      <Text>{tosPromptText}</Text>
      <TextInputLabel
        onChange={e => onDisplayNameChange(e.target.value)}
        value={displayName}
        label={enterNameText}
        maxLength="50"
        autoComplete="name"
        containerStyle={{ margin: 0 }}
      />
      <CheckWrapper>
        <Checkbox checked={tosAccepted} onClick={toggleAccepted} />
        <Text>
          {swapCopyVariables(termsText, {
            TERMS_LINK: <Link onClick={openTOS}>{termsLinkText}</Link>,
            POLICY_LINK: <Link onClick={openPrivacy}>{privacyLinkText}</Link>,
          })}
        </Text>
      </CheckWrapper>
      <SubmitButton id="checkinClient" disabled={disabled} full>
        {loading ? <LoadingBounce /> : joinSessionText}
      </SubmitButton>
    </Form>
  );
};

export default CheckInForm;
