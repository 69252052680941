import React from "react";
import styled from "styled-components";

import Panel from "../Panel";
import useCopy from "../../hooks/useCopy";
import checkIcon from "../../assets/checkmark.svg";
import { Buttons } from "../AppStoreLinks";

const Wrapper = styled.div``;

const Content = styled.div`
  width: 100%;
  gap: 16px;
  display: block;
  align-items: center;
  @media (min-width: 720px) {
    display: flex;
  }
`;

const Description = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  margin-bottom: 16px;
  @media (min-width: 720px) {
    display: flex;
    margin-bottom: 0;
  }
`;

const Checkmark = styled.div`
  background: url(${checkIcon});
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const BannerHeading = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.3;
  margin-bottom: 4px;
`;

const BannerText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.normal};
  line-height: 1.5;
`;

const ButtonWrapper = styled.div``;

const NativeAppBanner = ({ therapist }) => {
  const [heading, body, clientBody] = useCopy([
    "session.complete",
    "native.appBanner",
    "native.appBannerClient",
  ]);
  return (
    <Wrapper>
      <Panel>
        <Content>
          <Description>
            <BannerHeading>
              <Checkmark />
              {heading}
            </BannerHeading>
            <BannerText>{therapist ? body : clientBody}</BannerText>
          </Description>
          <ButtonWrapper>
            <Buttons app={therapist ? "professionals" : "sessions"} />
          </ButtonWrapper>
        </Content>
      </Panel>
    </Wrapper>
  );
};

export default NativeAppBanner;
