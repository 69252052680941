import React from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import ErrorBoundary from "./components/ErrorBoundary";
import { CopyProvider } from "./providers/CopyProvider";
import { AppStateProvider } from "./providers/AppStateProvider";
import AnalyticsProvider from "./providers/AnalyticsProvider";
import { v4 as uuidv4 } from "uuid";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.light};
    -webkit-font-smoothing: antialiased;
  }
`;

const AppContainer = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.default};
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

function App({ children }) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <AppContainer>
          <GlobalStyle />
          <AnalyticsProvider>
            <AppStateProvider>
              <CopyProvider lang={navigator.language}>
                <ErrorBoundary>{children}</ErrorBoundary>
              </CopyProvider>
            </AppStateProvider>
          </AnalyticsProvider>
        </AppContainer>
      </ThemeProvider>
    </>
  );
}

export default App;

export const appInstanceId = uuidv4();
