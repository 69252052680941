import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.button`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  appearance: none;
  outline: none;
  background: ${({ theme }) => theme.colors.primaryBlue};
  padding: 10px 16px;
  border-radius: 6px;

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSize.normal};
`;

const AddButton = ({ onClick, inSession, ...rest }) => {
  const [addText, startText] = useCopy(["chat.addText", "chat.startText"]);

  return (
    <Wrapper onClick={onClick} {...rest}>
      <Text>{inSession ? addText : startText}</Text>
    </Wrapper>
  );
};

export default AddButton;
