import React, { useState, useEffect, useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled, { useTheme } from "styled-components";
import useVideoContext from "../../hooks/useVideoContext";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { requestStartSession, requestEndSession } from "../../api/httpapi";
import { datadogEvent } from "../../datadog";
import Panel from "../Panel";
import LocalVideoPreview from "../LocalVideoPreview";
import RoomSetup from "../RoomSetup";
import InviteToSessionModal from "../InviteToSessionModal";
import InviteSentNotification from "../InviteSentNotification";
import DesktopNotificationsBanner from "../DesktopNotificationsBanner";
import WaitingRoomShare from "../WaitingRoomShare";
import VideoAndMicSettings from "../VideoAndMicSettings";
import Header from "./Header";
import ClientList from "./ClientList";
import useCopy from "../../hooks/useCopy";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  position: relative;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 16px;
    flex-direction: row;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50%;
  }
`;

const VideoWrapper = styled.div`
  height: 320px;
  /*
  Desktop and mobile have different video positions within the panel.
  We can't conditionally render them dependeing on the size because there
  can only be one video instance, so we have to absolute position it instead.
  */
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    position: absolute;
    right: 0;
    top: 0;
    width: calc(50% - 8px);
    height: 100%;
    margin: 0;
  }
`;

const Instructions = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    text-align: left;
  }
`;

const ClientsWrapper = styled.div`
  flex: 1;
`;

const BannerWrapper = styled.div`
  margin-bottom: 24px;
`;

const TherapistVideoPanel = ({ setStartSessionFailed }) => {
  const { isAcquiringLocalTracks, localTrackError } = useVideoContext();
  const [changingMediaSettings, setChangingMediaSettings] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [messageSentModal, setMessageSentModal] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [loadingIdentity, setLoadingIdentity] = useState("");
  const componentIsMounted = useRef(true);
  const theme = useTheme();
  const [instructionsText] = useCopy(["setupCheck.instructions"]);
  const xs = !useMediaQuery(`(min-width:${theme.breakpoints.desktop})`);

  const {
    state: { websocket, waitingRoom },
  } = useAppStateContext();

  const handleMessageSentModalClose = () => {
    setTimeout(() => {
      setMessageSentModal(null);
      setClientName(null);
    }, 10000);
  };

  const handleNotificationModalClose = (success, clientName) => {
    setShowInviteModal(false);
    // Anything besids true/false, reject
    if (success !== true && success !== false) {
      return;
    }
    const modal = success ? "success" : "fail";
    setClientName(clientName);
    setMessageSentModal(modal);
  };

  const disableChat = !websocket.isConnected || !waitingRoom.therapistAvailable;

  const disableStart =
    isAcquiringLocalTracks || localTrackError || !websocket.isConnected;

  const startSession = async ({ participantId }) => {
    setStartSessionFailed(false);
    setLoadingIdentity(participantId);

    const result = await requestStartSession([participantId]);
    if (!result) {
      console.error("Something went wrong starting session");
      setLoadingIdentity("");
      setStartSessionFailed(true);
      return;
    }

    // wait a few seconds and if the component is still mounted,
    // it means that the session could not be started and in that
    // case show an error and end the session
    setTimeout(() => {
      if (componentIsMounted.current) {
        console.error("Therapist did not receive the session ready message");

        setLoadingIdentity("");
        setStartSessionFailed(true);
        requestEndSession();

        datadogEvent({
          category: "session",
          event: "ended",
          component: "TherapistVideoPanel",
        });
      }
    }, 10000);
  };

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const toggleMediaSettings = active => {
    setChangingMediaSettings(active);
  };

  return (
    <>
      <BannerWrapper>
        <DesktopNotificationsBanner />
        <RoomSetup />
      </BannerWrapper>
      <VideoAndMicSettings
        active={changingMediaSettings}
        overlap={true}
        onClose={() => toggleMediaSettings(false)}
        testsEnabled={true}
      />
      <InviteSentNotification
        message={messageSentModal}
        name={clientName}
        close={handleMessageSentModalClose}
      />
      {showInviteModal && (
        <InviteToSessionModal close={handleNotificationModalClose} />
      )}
      <Panel>
        <Content>
          <Column>
            <Header />
            {!xs ? <Instructions>{instructionsText}</Instructions> : null}
            <VideoWrapper>
              <LocalVideoPreview
                showMediaControls={true}
                onMediaSettingsOpen={() =>
                  toggleMediaSettings(!changingMediaSettings)
                }
                isTherapist={true}
              />
            </VideoWrapper>
            {xs ? <Instructions>{instructionsText}</Instructions> : null}
            <ClientsWrapper>
              <ClientList
                chatDisabled={disableChat}
                startDisabled={disableStart}
                startSession={startSession}
                loadingIdentity={loadingIdentity}
              />
            </ClientsWrapper>
            <WaitingRoomShare onSendInvite={() => setShowInviteModal(true)} />
          </Column>
          <Column />
        </Content>
      </Panel>
    </>
  );
};

export default TherapistVideoPanel;
