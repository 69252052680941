import React from "react";
import styled from "styled-components";
import { Link, Label } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";

const BaaWrapper = styled.div`
  font-size: 17px;
  line-height: 1.5;
`;

function BAASettings() {
  const { room } = useWaitingRoomContext();
  const { analyticsEvent } = useAnalyticsContext();
  const [baaLink, baaLinkClickAction, baaHeading] = useCopy([
    "settings.baaLink",
    "settings.baaLinkClickAction",
    "settings.baaHeading",
  ]);

  const handleClick = async () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "baa-account-settings",
    });

    const url = `https://member.psychologytoday.com/${room.countryCode.toLowerCase()}/profile/compliance`;

    window.open(url, "_blank");
  };

  return (
    <>
      <Label>{baaHeading}</Label>
      <BaaWrapper>
        {swapCopyVariables(baaLink, {
          CLICK_ACTION: <Link onClick={handleClick}>{baaLinkClickAction}</Link>,
        })}
      </BaaWrapper>
    </>
  );
}

export default BAASettings;
