import React from "react";
import styled from "styled-components";
import useRoomState from "../../hooks/useRoomState";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.default};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const Text = styled.div`
  padding: 0 10%;
  line-height: 1.5;
`;

export default function Reconnecting() {
  const roomState = useRoomState();
  const [reconnecting] = useCopy(["connectivity.reconnecting"]);

  if (roomState === "reconnecting") {
    return (
      <Wrapper>
        <Text>{reconnecting}</Text>
      </Wrapper>
    );
  }

  return null;
}
